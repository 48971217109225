<template>
  <scroll-bar>
    <el-menu
      mode="vertical"
      :show-timeout="200"
      :default-active="activePath"
      :collapse="isCollapse"
      background-color="#1E1E2D"
      text-color="#A2A4B8"
      active-text-color="#fff"
      :unique-opened="true"
    >
      <sidebar-item :menuList="menuList"></sidebar-item>
    </el-menu>
  </scroll-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import ScrollBar from '@/components/ScrollBar'

export default {
  components: { SidebarItem, ScrollBar },
  computed: {
    ...mapGetters(['sidebar', 'menuList', 'activePath']),
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
