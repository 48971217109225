<template>
  <div :class="[isdashBoard ? 'bgc1' : 'bgc2', 'scroll-container']" ref="scrollContainer" @wheel.prevent="handleScroll">
    <div class="logo-wrapper stlogo" :style="logoBackground" v-if="!isCollapse">
      <div style="height: 35px; width: 100%">
        <img v-if="logo !== ''" :src="logo" class="stimg" />
        <img v-else-if="has_config_default_logo" class="stimg" :src="logo_default_big" />
      </div>
    </div>
    <div class="logo-wrapper stlogo" :style="logoBackground" v-else>
      <div style="height: 25px; width: 25px">
        <img v-if="smallLogo !== ''" :src="smallLogo" class="stimg" />
        <img v-else-if="has_config_default_logo" :src="logo_default_small" class="stimg" />
      </div>
    </div>
    <div class="scroll-wrapper" ref="scrollWrapper" @click="onNavChildClick" :style="{ top: top + 'px' }">
      <slot></slot>
    </div>
    <div class="hamb">
      <i class="iconfont hamburger" @click="toggleSideBar" :class="{ 'is-active': sidebar.opened }">&#xe729;</i>
    </div>
  </div>
</template>

<script>
const delta = 15
import { mapGetters } from 'vuex'
import logo_default_small from '@/assets/images/logo_default_small.png'
import logo_default_big from '@/assets/images/logo_default_big.png'
import logo_dashboard from '@/assets/images/logo_dashboard.png'

export default {
  name: 'scrollBar',
  data() {
    return {
      top: 58,
      isdashBoard: true,
      logo_default_small,
      logo_default_big,
      logo_dashboard,
      has_config_default_logo: window.globalConfig.defaultLogo
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'logo', 'smallLogo']),
    isCollapse() {
      return !this.sidebar.opened
    },
    logoBackground() {
      return { backgroundColor: this.isdashBoard ? '#1a1a27' : '#1a1a27' } // '#767986'
    }
  },
  watch: {
    $route: {
      handler(v) {
        this.isdashBoard = v.path === '/dashboard'
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    onNavChildClick() {
      setTimeout(() => {
        const container = this.$refs.scrollContainer
        const content = this.$refs.scrollWrapper
        const containerHeight = container.offsetHeight
        const contentHeight = content.offsetHeight
        if (contentHeight < containerHeight) {
          this.top = 58
        }
      }, 300)
    },
    handleScroll(e) {
      const eventDelta = e.wheelDelta || -e.deltaY * 3
      const $container = this.$refs.scrollContainer
      const $containerHeight = $container.offsetHeight
      const $wrapper = this.$refs.scrollWrapper
      const $wrapperHeight = $wrapper.offsetHeight
      if (eventDelta > 0) {
        this.top = Math.min(58, this.top + eventDelta)
      } else {
        if ($containerHeight - delta < $wrapperHeight) {
          if (this.top < -($wrapperHeight - $containerHeight + delta)) {
            // this.top = this.top
          } else {
            this.top = Math.max(this.top + eventDelta, $containerHeight - $wrapperHeight - delta)
          }
        } else {
          this.top = 58
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.bgc1 {
  background-color: $menuBg;
  // background: linear-gradient(90deg, rgba(47, 53, 75, 0.64) 0%, rgba(29, 34, 52, 0.64) 100%);
}
.bgc2 {
  background-color: $menuBg;
}

.stlogo {
  height: 58px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stimg {
  height: 100%;
  display: block;
  margin: 0 auto;
}

.scroll-container {
  position: relative;
  width: 100%;
  height: 100%;
  .logo-wrapper {
    position: relative;
    z-index: 999;
  }
  .scroll-wrapper {
    position: absolute;
    width: 100% !important;
    .router-link-active {
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.hamb {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-color: #1e1e2d;
}
.hamburger {
  // position: absolute;
  // bottom: 20px;
  // left: 20px;
  margin-left: 18px;
  line-height: 30px;
  color: #666666;
  font-size: 20px;
  display: inline-block;
  cursor: pointer;
  transform: rotate(180deg);
  transition: 0.38s;
  transform-origin: 50% 50%;
}
.hamburger.is-active {
  transform: rotate(0deg);
}
</style>
