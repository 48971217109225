import Cookies from 'js-cookie'

const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop',
    illegalListQuery: { current: 1, size: 10 },
    dashboard: {
      isTopBox: true,
      isRightBox: true,
      hideChat1: false,
      hideChat2: false,
      hideChat3: false
    }
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
    },
    CLOSE_SIDEBAR: state => {
      Cookies.set('sidebarStatus', 1)
      // 设置成flase在工作台页面默认关闭
      state.sidebar.opened = true
    },
    OPENED_SIDEBAR: state => {
      Cookies.set('sidebarStatus', 0)
      state.sidebar.opened = true
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    Change_IllegalListQuery: (state, currentPage) => {
      state.illegalListQuery.current = currentPage
    },
    CHANGE_DASHBOARD(state, payload) {
      state.dashboard = payload
    }
  },
  actions: {
    ChangeIllegalListQuery: ({ commit }, currentPage) => {
      commit('Change_IllegalListQuery', currentPage)
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar({ commit }) {
      commit('CLOSE_SIDEBAR')
    },
    OpenedSideBar({ commit }) {
      commit('OPENED_SIDEBAR')
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    }
  }
}

export default app
