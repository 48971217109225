// date.js
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function str2Date(dateStr, separator) {
  if (!separator) {
    separator = '-'
  }
  let dateArr = dateStr.split(separator)
  let year = parseInt(dateArr[0])
  let month
  //处理月份为04这样的情况
  if (dateArr[1].indexOf('0') == 0) {
    month = parseInt(dateArr[1].substring(1))
  } else {
    month = parseInt(dateArr[1])
  }
  let day = parseInt(dateArr[2])
  let date = new Date(year, month - 1, day)
  return date
}

export function nowadays() {
  //今天的时间
  var day = new Date()
  day.setTime(day.getTime())
  var nowaday = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
  var date = new Date(nowaday)
  return [date.getTime(), date.getTime()]
}

export function today() {
  //今天的时间
  var day = new Date()
  day.setTime(day.getTime())
  var nowaday = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
  var date = new Date(nowaday)
  return date.getTime()
}

// 当前时间
export function currentTime() {
  var date = new Date()
  var seperator1 = '-'
  var seperator2 = ':'
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var strDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var currentdate =
    date.getFullYear() +
    seperator1 +
    month +
    seperator1 +
    strDate +
    ' ' +
    date.getHours() +
    seperator2 +
    date.getMinutes() +
    seperator2 +
    date.getSeconds()
  return currentdate
}

/**
 * 格式化时间戳
 * @param val 时间戳
 * @returns {string}
 */
export function formatDayTime(val) {
  if (val) {
    let date = new Date(val)
    let Y = date.getFullYear()
    let M = date.getMonth() + 1
    let D = date.getDate()
    let H = date.getHours()
    let Min = date.getMinutes()
    let S = date.getSeconds()
    if (M < 10) M = '0' + M
    if (D < 10) D = '0' + D
    if (H < 10) H = '0' + H
    if (Min < 10) Min = '0' + Min
    if (S < 10) S = '0' + S
    return `${Y}-${M}-${D} ${H}:${Min}:${S}`
  } else {
    return ''
  }
}
