<template>
  <div class="app-wrapper" :class="classObj">
    <sidebar class="sidebar-container"></sidebar>
    <div class="main-container">
      <navbar></navbar>
      <app-main></app-main>
    </div>
    <div :class="showAlarm ? 'alarm' : ''"></div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapGetters } from 'vuex'

export default {
  name: 'layout',
  components: { Navbar, Sidebar, AppMain },
  mixins: [ResizeMixin],
  computed: {
    ...mapGetters(['sidebar', 'illegalEventInfoSock', 'hRingSOSData']),
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    roles() {
      return this.$store.state.user.roles
    },
    routes() {
      if (this.$route.path === '/dashboard') {
        this.$store.dispatch('CloseSideBar')
      } else {
        this.$store.dispatch('OpenedSideBar')
      }
      return 666
    }
  },
  data() {
    return {
      showAlarm: false,
      showAlarmDuration: window.globalConfig.warning.duration,
      alarmTimer: null
    }
  },
  watch: {
    routes(newV) {
      console.log(newV)
    },
    illegalEventInfoSock(newV) {
      if (Object.keys(newV).length > 0) {
        this.showAlarm = true
        // const timer = setTimeout(() => {
        //   this.showAlarm = false
        // }, 3000)
        // this.$once('hook:beforeDestroy', () => {
        //   clearInterval(timer)
        // })
        if (this.alarmTimer) {
          clearInterval(this.alarmTimer)
        }
        this.alarmTimer = setTimeout(() => {
          this.showAlarm = false
        }, this.showAlarmDuration)
      }
    },
    //智能头环SOS报警
    hRingSOSData(newV) {
      if (Object.keys(newV).length > 0) {
        this.showAlarm = true
        if (this.alarmTimer) {
          clearInterval(this.alarmTimer)
        }
        this.alarmTimer = setTimeout(() => {
          this.showAlarm = false
        }, this.showAlarmDuration)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/mixin.scss';
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}
.main-container {
  background-color: $bg-color;
}
.alarm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  pointer-events: none;
  margin: 0;
  z-index: 3000;
  box-shadow: inset 0 0 70px #f00;
  animation: twinkling 1s infinite normal;
}
@keyframes twinkling {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
