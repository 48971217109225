import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import store from '@/store/index'
import { getwsMessageTopic } from '@/api/device'
import { config } from './config'

import {
  getToken
  // getwsMessageTopic
} from '@/utils/auth'

// import { Notification } from 'element-ui'
const themePond = {}
let stompClient = null

let deviceClient = null
let deviceBySerialNoClient = null

// let maxConnectionAttempts = 10
// let currentTry = 0

export function initWebSocket() {
  connectWebSocket()
}

export function checkWebSocket(deviceSerialNo) {
  if (deviceSerialNo !== '' && deviceSerialNo !== null && deviceSerialNo !== undefined) {
    // 判断有没有订阅过全局设备
    if (deviceClient !== null) {
      deviceClient.unsubscribe()
      deviceClient = null
    }
    // 判断是不是二次订阅某个设备
    if (deviceBySerialNoClient) {
      deviceBySerialNoClient.unsubscribe()
      console.log('utils/ws.js ... 开始二次订阅单个设备')
      deviceBySerialNoClient = stompClient.subscribe('/topic/realtime/' + deviceSerialNo + '', msg => {
        let data = JSON.parse(msg.body)
        store.dispatch('GetDeviceData', data)
      })
    } else {
      // 订阅单个设备
      console.log('utils/ws.js ... 开始订阅单个设备')
      deviceBySerialNoClient = stompClient.subscribe('/topic/realtime/' + deviceSerialNo + '', msg => {
        let data = JSON.parse(msg.body)
        store.dispatch('GetDeviceData', data)
      })
    }
  } else {
    // 取消单个设备订阅，判断单个设备订阅载体，防止重复取消订阅程序报错
    if (deviceBySerialNoClient) {
      deviceBySerialNoClient.unsubscribe()
      deviceBySerialNoClient = null
      // deviceClient = stompClient.subscribe("/topic/realtime", msg => {
      //   let data = JSON.parse(msg.body);
      //   store.dispatch("GetDeviceData", data);
      // });
    }
  }
}

export function subscribe(theme, callback) {
  if (!themePond[theme]) {
    themePond[theme] = [callback]
  } else {
    const position = themePond[theme].indexOf(callback)
    if (position === -1) {
      themePond[theme].push(callback)
    }
  }
  return function cancel() {
    const position = themePond[theme].indexOf(callback)
    themePond[theme].splice(position, 1)
  }
}

function connectWebSocket() {
  // 建立连接对象
  let ws_url = ''
  if (process.env.NODE_ENV === 'development') {
    ws_url = config.testWebSocketUrl
  } else if (process.env.NODE_ENV === 'test') {
    ws_url = config.testWebSocketUrl
  } else if (process.env.NODE_ENV === 'production') {
    ws_url = config.productionWebSocketUrl
  }

  let token = getToken()
  let socket = new SockJS(ws_url + '?token=' + token, '', {
    timeout: 10000
  })
  stompClient = Stomp.over(socket)
  stompClient.connect(
    {},
    () => {
      store.dispatch('SetDeviceSockType', true)
      successCallback()
    },
    () => {
      store.dispatch('SetDeviceSockType', false)
      errorCallback()
    }
  )
}

function successCallback() {
  getwsMessageTopic().then(response => {
    let wsMessageTopic = response.result || []
    wsMessageTopic.forEach(obj => {
      let messageType = obj.messageType
      let messageTopic = obj.messageTopic[0]
      switch (messageType) {
        case 'user':
          stompClient.subscribe(messageTopic, msg => {
            let data = JSON.parse(msg.body)
            store.dispatch('GetIllegalEventInfoSock', data, 'error')
          })
          break
        case 'illegalEvent':
          stompClient.subscribe(messageTopic, msg => {
            let data = JSON.parse(msg.body)
            store.dispatch('GetIllegalEventInfoSock', data, 'error')
          })
          break
        default:
          stompClient.subscribe(messageTopic, msg => {
            const handlePond = themePond[messageTopic]
            if (Array.isArray(handlePond) && handlePond.length) {
              themePond[messageTopic].forEach(function (callback) {
                callback(JSON.parse(msg.body))
              })
            }
          })
      }
    })
  })
}

function errorCallback() {
  setTimeout(() => {
    connectWebSocket()
  }, 5000)
}

// 人工辅助ws
let monitorStompClient

export function connectMonitorWs() {
  let ws_url = ''
  if (process.env.NODE_ENV === 'development') {
    ws_url = config.testWebSocketUrl
  } else if (process.env.NODE_ENV === 'test') {
    ws_url = config.testWebSocketUrl
  } else if (process.env.NODE_ENV === 'production') {
    ws_url = config.productionWebSocketUrl
  }

  let token = getToken()
  let socket = new SockJS(ws_url + '?token=' + token + '&monitor=monitor', '', {
    timeout: 10000
  })
  monitorStompClient = Stomp.over(socket)
  // monitorStompClient.connect(
  //   { serialNo: serialNo },
  //   () => {
  //     // okCallback(serialNo, uuid)
  //     console.log('连上了')
  //   },
  //   () => {
  //     setTimeout(() => {
  //       connectMonitorWs()
  //     }, 5000)
  //   }
  // )
  return monitorStompClient
}

export function okCallback(serialNo, uuid) {
  // 获取客户端连接编号
  monitorStompClient.send(`/app/monitor/client/code/${serialNo}`, { 'content-type': 'text/plain' }, uuid)
  // 客户端连接编号下发
  monitorStompClient.subscribe(`/topic/monitor/client/code/${serialNo}/${uuid}`, msg => {
    let data = JSON.parse(msg.body)
    console.log(data)
  })

  // 申请主控制
  monitorStompClient.send(`/app/monitor/main/control/${serialNo}`)
  // 主控制信号下发
  monitorStompClient.subscribe(`/topic/monitor/control/${serialNo}`, msg => {
    let data = msg.body
    console.log(data)
    // 如果自己的客户端的连接编号和主控制端的连接编号一致，那么自己就是主控制端
  })

  // 实时快照下发
  monitorStompClient.subscribe(`/topic/monitor/live/snapshot/${serialNo}`, msg => {
    let data = JSON.parse(msg.body)
    console.log(data)
  })

  // ai识别结果下发
  monitorStompClient.subscribe(`/topic/monitor/ai/result/${serialNo}`, msg => {
    let data = JSON.parse(msg.body)
    console.log(data)
  })

  // 拦截规则下发
  monitorStompClient.subscribe(`/topic/monitor/intercept/${serialNo}`, msg => {
    let data = JSON.parse(msg.body)
    console.log(data)
  })

  // 缓存时间下发
  monitorStompClient.subscribe(`/topic/monitor/cache/time/${serialNo}`, msg => {
    let data = JSON.parse(msg.body)
    console.log(data)
  })
}

// 更新图片状态
export function updateImage(serialNo, params) {
  monitorStompClient.send(`/app/monitor/image/status/${serialNo}`, { 'content-type': 'text/plain' }, params)
}

// 销毁ws连接
export function disconnect() {
  if (monitorStompClient) {
    monitorStompClient.disconnect()
  }
}
