const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  illegalListQuery: state => state.app.illegalListQuery,
  token: state => state.user.token,
  accessToken: state => state.user.accessToken,
  avatar: state => state.user.avatar,
  accountType: state => state.user.accountType,
  menuList: state => state.user.menuList,
  activePath: state => state.user.activePath,
  originMenuList: state => state.user.originMenuList,
  name: state => state.user.name,
  realName: state => state.user.realName,
  roles: state => state.user.roles,
  sysOrganization: state => state.user.sysOrganization,
  sysDataPermission: state => state.user.sysDataPermission,
  sysDataPermissionByDevice: state => state.user.sysDataPermissionByDevice,
  pathPermissionList: state => state.user.pathPermissionList,
  illegalEventInfoSock: state => state.ws.illegalEventInfoSock,
  illegalEventInfoSockType: state => state.ws.illegalEventInfoSockType,
  deviceSockType: state => state.ws.deviceSockType,
  total: state => state.ws.total,
  eventId: state => state.ws.eventId,
  deviceData: state => state.ws.deviceData,
  deviceGasData: state => state.ws.deviceGasData,
  logo: state => state.user.logo,
  smallLogo: state => state.user.smallLogo,
  // Internet: state => state.user.Internet,
  hRingSOSData: state => state.ws.hRingSOSData,
  talkGroupNumber: state => state.ws.talkGroupNumber,
  members: state => state.ws.members,
  extUrl: state => state.ws.extUrl,
  hRtcDispatch: state => state.user.hRtcDispatch
}
export default getters
