import { getAllUnreadCount } from '@/api/messageCenter.serve'
const ws = {
  state: {
    userInfoSock: [],
    userInfoSockType: '',
    illegalEventInfoSock: [],
    illegalEventInfoSockType: '',
    eventId: '',
    deviceData: [],
    deviceGasData: [],
    deviceSockType: false,
    total: 0,
    hRingSOSData: null,
    talkGroupNumber: '',
    members: '',
    memberState: {},
    callId: '',
    memberStateList: [],
    extUrl: ''
  },

  mutations: {
    SET_USER_INFO: (state, data, type) => {
      state.userInfoSock = data
      state.userInfoSockType = type
    },
    SET_ILLEGAL_EVENT_INFO: (state, data, type) => {
      state.illegalEventInfoSock = data
      state.illegalEventInfoSockType = type
    },
    SET_EVENT_ID: (state, id) => {
      state.eventId = id
    },
    SET_DEVICE_DATA: (state, data) => {
      state.deviceData = data
    },
    SET_DEVICE_GAS_DATA: (state, data) => {
      state.deviceGasData = data
    },
    SET_DEVICE_SOCK_TYPE: (state, type) => {
      state.deviceSockType = type
    },
    SET_ILL_TOTAL: (state, total) => {
      state.total = total
    },
    SET_HRINGSOS_DATA: (state, data) => {
      state.hRingSOSData = data
    },
    SET_GROUP_NUMBER: (state, data) => {
      state.talkGroupNumber = data
    },
    SET_MEMBERS: (state, data) => {
      state.members = data
    },
    SET_MEMBER_STATE: (state, data) => {
      state.memberState = data
    },
    SET_CALL_ID: (state, data) => {
      state.callId = data
    },
    SET_MEMBER_LIST: (state, data) => {
      state.memberStateList = data
    },
    SET_EXT_URL: (state, data) => {
      state.extUrl = data
    }
  },

  actions: {
    GetUserInfoSock({ commit }, data, type) {
      commit('SET_USER_INFO', data, type)
    },
    GetIllegalEventInfoSock({ commit }, data, type) {
      commit('SET_ILLEGAL_EVENT_INFO', data, type)
    },
    GetEventId({ commit }, id) {
      commit('SET_EVENT_ID', id)
    },
    GetDeviceData({ commit }, data) {
      commit('SET_DEVICE_DATA', data)
    },
    GetDeviceGasData({ commit }, data) {
      commit('SET_DEVICE_GAS_DATA', data)
    },
    SetDeviceSockType({ commit }, type) {
      commit('SET_DEVICE_SOCK_TYPE', type)
    },
    async GetIllTotal({ commit }) {
      const [error, total] = await getAllUnreadCount()
      if (error) {
        return
      }
      commit('SET_ILL_TOTAL', total)
    },
    GetHRingSOSData({ commit }, data) {
      commit('SET_HRINGSOS_DATA', data)
    },
    SetMembersList({ state }) {
      console.log('state', state.memberStateList)
    }
  }
}

export default ws
