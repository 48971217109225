<template>
  <div class="menu-wrapper">
    <template v-for="item in menuList">
      <template v-if="!item.hidden">
        <template v-if="item.children">
          <router-link
            v-if="hasOneShowingChildren(item.children) && !item.children[0].children && !item.alwaysShow"
            :to="item.children[0].path"
            :key="item.children[0].name"
          >
            <el-menu-item :index="item.children[0].path" :class="{ 'submenu-title-noDropdown': !isNest }">
              <i
                v-if="item.children[0].meta && item.children[0].meta.icon"
                class="iconfont"
                :style="iconColor"
                v-html="item.children[0].meta.icon"
              ></i>
              <!-- <svg-icon v-if="item.children[0].meta&&item.children[0].meta.icon"
                    :icon-class="item.children[0].meta.icon"></svg-icon> -->
              <span v-if="item.children[0].meta && item.children[0].meta.title" slot="title">
                {{ item.children[0].meta.title }}
              </span>
            </el-menu-item>
          </router-link>

          <el-submenu
            v-else
            :index="item.name || item.path"
            :key="item.name"
            :class="isdashBoard ? 'sub-title' : 'not-dash'"
          >
            <template slot="title">
              <i v-if="item.meta && item.meta.icon" class="iconfont" :style="iconColor" v-html="item.meta.icon"></i>
              <span v-if="item.meta && item.meta.title" slot="title">{{ item.meta.title }}</span>
            </template>

            <template v-for="child in item.children">
              <template v-if="!child.hidden">
                <template v-if="child.openType === 1">
                  <sidebar-item
                    :is-nest="true"
                    class="nest-menu"
                    v-if="child.children && child.children.length > 0"
                    :menuList="[child]"
                    :key="child.path"
                  ></sidebar-item>

                  <router-link
                    v-else
                    :to="{
                      path: EXT_PAGE
                    }"
                    :key="child.name"
                    @click.native="handleLink(child.path)"
                  >
                    <el-menu-item :index="child.path">
                      <!-- <svg-icon icon-class="dots"
                          style="width:0.8rem;height:0.8rem;margin-right: 12px;"></svg-icon> -->
                      <span v-if="child.meta && child.meta.title" slot="title">{{ child.meta.title }}</span>
                    </el-menu-item>
                  </router-link>
                </template>
                <template v-else>
                  <sidebar-item
                    :is-nest="true"
                    class="nest-menu"
                    v-if="child.children && child.children.length > 0"
                    :menuList="[child]"
                    :key="child.path"
                  ></sidebar-item>

                  <router-link v-else :to="child.path" :key="child.name">
                    <el-menu-item :index="child.path">
                      <!-- <svg-icon icon-class="dots"
                          style="width:0.8rem;height:0.8rem;margin-right: 12px;"></svg-icon> -->
                      <span v-if="child.meta && child.meta.title" slot="title">{{ child.meta.title }}</span>
                    </el-menu-item>
                  </router-link>
                </template>
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <template v-if="item.openType === 1">
            <router-link
              :to="{
                path: EXT_PAGE
              }"
              :key="item.name"
              @click.native="handleLink(item.path)"
            >
              <el-menu-item
                :index="item.path"
                :class="{ 'submenu-title-noDropdown': !isNest, 'dash-menu-item': isdashBoard }"
              >
                <i v-if="item.meta && item.meta.icon" class="iconfont" :style="iconColor" v-html="item.meta.icon"></i>
                <span v-if="item.meta && item.meta.title" slot="title">{{ item.meta.title }}</span>
              </el-menu-item>
            </router-link>
          </template>
          <template v-else>
            <router-link :to="item.path" :key="item.name">
              <el-menu-item
                :index="item.path"
                :class="{ 'submenu-title-noDropdown': isNest, 'dash-menu-item': isdashBoard }"
              >
                <i v-if="item.meta && item.meta.icon" class="iconfont" :style="iconColor" v-html="item.meta.icon"></i>
                <!-- <svg-icon v-if="item.children[0].meta&&item.children[0].meta.icon"
                      :icon-class="item.children[0].meta.icon"></svg-icon> -->
                <span v-if="item.meta && item.meta.title" slot="title">{{ item.meta.title }}</span>
              </el-menu-item>
            </router-link>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { EXT_PAGE } from '@/utils/constant'

export default {
  name: 'SidebarItem',
  props: {
    menuList: { type: Array },
    isNest: { type: Boolean, default: false }
  },
  data() {
    return {
      isdashBoard: true
    }
  },
  watch: {
    $route: {
      handler(v) {
        this.isdashBoard = v.path === '/dashboard'
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    iconColor() {
      return this.isdashBoard ? { color: '#eee' } : ''
    },
    EXT_PAGE() {
      return EXT_PAGE
    }
  },
  methods: {
    hasOneShowingChildren(children) {
      children.filter(item => {
        return !item.hidden
      })
      // if (showingChildren.length === 1) {
      //   return true
      // }
      return false
    },
    handleLink(url) {
      localStorage.setItem(EXT_PAGE, url)
      this.$store.commit('SET_EXT_URL', url)
    }
  }
}
</script>

<style scoped lang="scss">
.menu-wrapper {
  padding-bottom: 56px;
}
.iconfont {
  margin-right: 12px;
}

.el-submenu.is-active .el-submenu__title > i,
.el-menu-item.is-active i {
  color: #5d78ff;
}

.el-menu--popup {
  .el-menu-item {
    background-color: rgba(78, 81, 99, 0.7) !important;
    &:hover {
      color: #fff !important;
      background-color: rgba(78, 81, 99, 0.5) !important;
    }
  }
}
.sub-title {
  ::v-deep .el-submenu__title:focus,
  ::v-deep .el-submenu__title:hover {
    background-color: rgba(78, 81, 99, 0.5) !important;
  }

  .el-submenu__title,
  .el-menu-item {
    background-color: rgba(78, 81, 99, 0.5) !important;
    &:hover {
      color: #fff !important;
      background-color: rgba(78, 81, 99, 0.3) !important;
    }
  }
}
.dash-menu-item {
  &:hover {
    background-color: rgba(78, 81, 99, 0.5) !important;
  }
}

.not-dash {
  .el-submenu__title,
  .el-menu-item {
    min-width: 180px !important;
    height: 56px;
    line-height: 56px;
    background-color: $subMenuBg !important;

    &:hover {
      color: #fff !important;
      background: linear-gradient(270deg, rgba(12, 201, 255, 0.3) 0%, rgba(33, 232, 246, 0) 100%);
    }
  }

  ::v-deep .el-menu-item.is-active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 18%;
      right: 0;
      width: 180px;
      height: 40px;
      border-right: 4px solid #21e8f6;
      background: linear-gradient(270deg, rgba(12, 201, 255, 0.3) 0%, rgba(33, 232, 246, 0) 100%);
    }
  }
}
</style>
