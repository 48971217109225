import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store/index'
// import store from '../store'
import qs from 'qs'
import { getToken } from '@/utils/auth'
import router from '../router'
let service
let baseUrl = localStorage.getItem('Internet') || window.globalConfig.targetUrl

window.addEventListener('setItemEvent', function (e) {
  baseUrl = e.newValue
  // service = axios.create({
  //     baseURL: e.newValue, // api的base_url
  //     timeout: 10000, // 请求超时时间
  //     paramsSerializer: function(params) {
  //         return qs.stringify(params, {
  //             arrayFormat: 'repeat'
  //         })
  //     }
  // })
})

// 创建axios实例

service = axios.create({
  baseURL: baseUrl, // api的base_url
  timeout: 10000, // 请求超时时间
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
})
// request拦截器
service.interceptors.request.use(
  config => {
    // 获取token
    if (getToken()) {
      config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // 判断参数
    if (config.url.indexOf(':') !== -1) {
      var num = 0 // 这个字符出现的次数
      var index = config.url.indexOf(':')
      while (index !== -1) {
        num++ // 每出现一次 次数加一
        index = config.url.indexOf(':', index + 1) // 从字符串出现的位置的下一位置开始继续查找
      }
      if (num > 1) {
        var url = config.params
          ? config.url.replace(/:[a-zA-Z]+(\/)?/, config.params[0] + '$1')
          : config.url.replace(/:[a-zA-Z]+(\/)?/, config.data[0] + '$1')
        if (url.indexOf(':') !== -1) {
          config.url = config.params
            ? url.replace(/:[a-zA-Z]+(\/)?/, config.params[1] + '$1')
            : url.replace(/:[a-zA-Z]+(\/)?/, config.data[1] + '$1')
        }
      } else {
        config.url = config.params
          ? config.url.replace(/:[a-zA-Z]+(\/)?/, config.params[0] + '$1')
          : config.url.replace(/:[a-zA-Z]+(\/)?/, config.data[0] + '$1')
        config.params ? (config.params = config.params[1]) : (config.data = config.data[1])
      }
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.code === undefined && response.headers['Content-Disposition'] === undefined) {
      return response
    }
    if (res.code !== 200) {
      if (res.code === 202) {
        return response.data
      } else if (res.code === 421) {
        // 登录错误
        Message({ type: 'error', message: res.message })
      } else if (res.code === 500) {
        Message({ type: 'error', message: '系统有错误，请联系管理员！' })
      } else if (res.code === 422) {
        // 创建同名用户
        Message({ type: 'error', message: res.message })
      } else if (response.config.url.indexOf('bind') !== -1) {
        Message({ type: 'error', message: res.message })
      } else if (res.code === 401) {
        // 权限不足
        Message({ type: 'error', message: res.message })
      } else if (res.code === 420) {
        // 服务器session失效
        store.dispatch('FedLogOut')
        router.push('/login')
        return null
      } else if (res.code === 509 || res.code == 508) {
        Message({ type: 'error', message: res.message })
      } else if (res.code == undefined) {
        return response.data
      } else {
        Message({ type: 'error', message: res.message })
      }
    } else {
      return response.data
    }
  },
  error => {
    // if (error.response.status === 401) {
    //   if (error.response.data.path.indexOf('wx') === -1) {
    //     Router.push('/403')
    //   }
    // }
    return Promise.reject(error)
  }
)

export default service
