import { login, logout } from '@/api/login'
import { getUserAuthority } from '@/api/users'
import {
  getToken,
  getAccessToken,
  getUsername,
  getAccountType,
  getUserAvatar,
  getRealName,
  // getOrgId,
  getLogo,
  getSmallLogo,
  setToken,
  removeToken,
  removeHRtcDispatch,
  getHRtcDispatch
} from '@/utils/auth'

import { formatTree } from '@/utils/tree'
const user = {
  state: {
    token: getToken(),
    accessToken: getAccessToken(),
    name: '',
    accountType: '',
    avatar: '',
    realName: '',
    menuList: [],
    activePath: '',
    logo: '',
    smallLogo: '',
    originMenuList: [],
    roles: [],
    sysOrganization: [],
    sysDataPermission: [],
    sysDataPermissionByDevice: [],
    // 当前页面权限列表
    pathPermissionList: [],
    hRtcDispatch: null
    // Internet: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ACCOUNTTYPE: (state, accountType) => {
      state.accountType = accountType
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatarUrl) => {
      state.avatar = avatarUrl
    },
    SET_REAL_NAME: (state, realName) => {
      state.realName = realName
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MENULIST: (state, menuList) => {
      state.menuList = menuList
    },
    SET_ACTIVE_PATH: (state, activePath) => {
      state.activePath = activePath
    },
    SET_ORIGIN_MENULIST: (state, originMenuList) => {
      state.originMenuList = originMenuList
    },
    SET_SYSORGANIZATION: (state, sysOrganization) => {
      state.sysOrganization = sysOrganization
    },
    SET_SYSDATAPERMISSION: (state, sysDataPermission) => {
      if (sysDataPermission !== '') {
        state.sysDataPermission = sysDataPermission
      } else {
        state.sysDataPermission = ''
      }
    },
    SET_SYSDATAPERMISSIONBYDEVICE: (state, sysDataPermissionByDevice) => {
      if (sysDataPermissionByDevice !== '') {
        state.sysDataPermissionByDevice = sysDataPermissionByDevice
      } else {
        state.sysDataPermissionByDevice = ''
      }
    },
    SET_PATH_PERMISSION_LIST: (state, pathPermissionList) => {
      state.pathPermissionList = pathPermissionList
    },
    SET_COMPANY_LOGO: (state, companyLogo) => {
      state.logo = companyLogo
    },
    SET_COMPANY_SMALL_LOGO: (state, companySmallLogo) => {
      state.smallLogo = companySmallLogo
    },
    SET_HRTC_DISPATCH: (state, data) => {
      state.hRtcDispatch = data
    }
    // SET_INTERNET: (state, Internet) => {
    //     state.Internet = Internet
    // }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      console.log(commit)
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password, userInfo.autoLogin, userInfo.isSha)
          .then(response => {
            const data = response.result
            const accessToken = 'Bearer ' + data.token
            setToken(accessToken)
            setToken(username, 'username')
            setToken(data.avatar, 'avatar')
            setToken(data.realName, 'realName')
            setToken(data.accountType, 'accountType')
            setToken(data.company.companyId, 'orgId')
            if (data.hRtcDispatch) {
              setToken(JSON.stringify(data.hRtcDispatch), 'hRtcDispatch')
              commit('SET_HRTC_DISPATCH', data.hRtcDispatch)
            }
            if (data.wsMessageTopic) {
              setToken(data.wsMessageTopic, 'wsMessageTopic')
            } else {
              setToken([], 'wsMessageTopic')
            }
            if (data.company.companyLogo) {
              setToken(data.company.companyLogo, 'logo')
            } else {
              setToken('', 'logo')
            }
            if (data.company.companySmallLogo) {
              setToken(data.company.companySmallLogo, 'smallLogo')
            } else {
              setToken('', 'smallLogo')
            }
            // setToken(data.org, 'orgId')
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        // let params = [getOrgId(), getUsername()]
        getUserAuthority()
          .then(response => {
            // 处理导航菜单
            commit('SET_MENULIST', formatTree(response.result))
            commit('SET_ORIGIN_MENULIST', response.result)
            commit('SET_ACCOUNTTYPE', getAccountType())
            commit('SET_NAME', getUsername())
            commit('SET_AVATAR', getUserAvatar())
            commit('SET_REAL_NAME', getRealName())
            commit('SET_COMPANY_LOGO', getLogo())
            commit('SET_COMPANY_SMALL_LOGO', getSmallLogo())
            commit('SET_HRTC_DISPATCH', getHRtcDispatch())
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            removeHRtcDispatch()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },
    // 获取用户权限信息
    GetSysDataPermission({ commit }, sysDataPermission) {
      let data = null
      if (sysDataPermission != '') {
        sysDataPermission = JSON.parse(sysDataPermission)
        data = {
          userIds: sysDataPermission.userIds,
          userOrgIds: sysDataPermission.userOrgIds
        }
      } else {
        data = ''
      }
      commit('SET_SYSDATAPERMISSION', data)
    },
    GetSysDataPermissionByDevice({ commit }, sysDataPermissionByDevice) {
      let data = null
      if (sysDataPermissionByDevice != '') {
        sysDataPermissionByDevice = JSON.parse(sysDataPermissionByDevice)
        data = {
          deviceIds: sysDataPermissionByDevice.deviceIds,
          deviceOrgIds: sysDataPermissionByDevice.deviceOrgIds
        }
      } else {
        data = ''
      }

      commit('SET_SYSDATAPERMISSIONBYDEVICE', data)
    },
    SetActivePath: ({ commit }, activePath) => {
      commit('SET_ACTIVE_PATH', activePath)
    },
    GetPathPermissionList: ({ commit }, pathPermissionList) => {
      commit('SET_PATH_PERMISSION_LIST', pathPermissionList)
    }
    // GetInternet: ({ commit }, Internet) => {
    //     commit("SET_INTERNET", Internet);
    // }
  }
}

export default user
