export const config = {
  // webSocketUrl: "https://api.shutongmofang.cn/ws",
  webSocketUrl: 'http://192.168.31.184:8370/ws',
  testWebSocketUrl:
    (localStorage.getItem('Internet') ? localStorage.getItem('Internet') + '/ws' : '') ||
    window.globalConfig.targetWsUrl,
  // testWebSocketUrl: "https://testapi.shutongmofang.cn/ws",
  productionWebSocketUrl:
    (localStorage.getItem('Internet') ? localStorage.getItem('Internet') + '/ws' : '') ||
    window.globalConfig.targetWsUrl, //"https://api.shutongmofang.cn/ws",
  illegalType: [
    { value: '0', label: '误报' },
    { value: '1', label: '未正确佩戴安全头盔' },
    { value: '2', label: '未正常佩戴护目镜' },
    { value: '3', label: '异常倒地' },
    { value: '4', label: '非授权用户' },
    { value: '5', label: '气体浓度异常' },
    { value: '6', label: '吸烟' },
    { value: '7', label: '打电话/看手机' }
  ],
  deviceIoT: {
    o2: '氧气值',
    so2: '二氧化硫值',
    co: '一氧化碳值',
    h2: '氢气值',
    ch4: '甲烷值',
    c2h6: '乙烷值',
    c3h8: '丙烷值',
    c4h10: '丁烷值',
    c2h4: '乙烯值',
    c3h6: '丙烯值',
    c2h2: '乙炔值',
    c4h8: '丁烯值',
    c3h4: '丙炔值',
    c4h6: '丁炔值',
    ph3: '磷化氢',
    ex: '可燃气体',
    locate: '定位坐标'
  },
  deviceRssi: ['极好', '好', '中等', '差', '很差'],
  jobType: [
    { value: '0', label: '编辑' },
    { value: '4', label: '进行中' },
    { value: '6', label: '结束' }
  ],
  userStatus: [
    { value: '0', label: '未激活' },
    { value: '1', label: '正常' },
    { value: '7', label: '到期' },
    { value: '8', label: '锁定' }
  ]
}
