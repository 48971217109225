<template>
  <div>
    <div class="navbar" v-if="!isdashBoard">
      <div class="page-title">
        <img src="@/assets/images/header_icon.png" class="picon" alt="" />
        <span class="menu">
          <span>{{ pageTitle }}</span>
        </span>
      </div>
      <div class="fl">
        <i class="el-icon-phone phone" v-if="contactNo.visible" @click="showContact = true"></i>
        <hamburger class="hamburger-container"></hamburger>
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <img class="user-avatar" :src="avatar ? avatar : require('@/assets/images/default-avatar.png')" />
            <span class="iser-name">
              {{ realName || name }}
            </span>
            <i class="el-icon-arrow-down uicon"></i>
          </div>
          <el-dropdown-menu class="user-dropdown" slot="dropdown">
            <el-dropdown-item style="width: 150px; text-align: center">
              <span @click="handleChangePassword" style="display: block">修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item style="width: 150px; text-align: center">
              <span @click="logout" style="display: block">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="bg" v-else>
      <div class="dashboard-header">
        <div class="page-title">{{ loginTitle }}</div>
        <div class="fl page-r">
          <div class="date">
            <i class="el-icon-date"></i>
            {{ getDatetime }}
          </div>
          <i class="el-icon-phone phone" v-if="contactNo.visible" @click="showContact = true"></i>
          <el-dropdown class="avatar-container" trigger="click">
            <div class="avatar-wrapper">
              <img class="user-avatar" :src="avatar ? avatar : require('@/assets/images/default-avatar.png')" />
              <span class="iser-name">
                {{ realName || name }}
              </span>
              <i class="el-icon-arrow-down uicon"></i>
            </div>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item style="width: 150px; text-align: center">
                <span @click="handleChangePassword" style="display: block">修改密码</span>
              </el-dropdown-item>
              <el-dropdown-item style="width: 150px; text-align: center">
                <span @click="logout" style="display: block">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img src="@/assets/images/dashboard/zhankai.png" alt="" class="con-img" @click="closeAllBox" />
        </div>
      </div>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="userChagePasswordVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleUserChangePasswordDialogClose"
    >
      <div class="content">
        <el-form ref="userChangePasswordForm" :model="userChangePasswordModel" size="small" label-width="90px">
          <el-form-item label="原密码">
            <el-input size="small" v-model.trim="userChangePasswordModel.oldPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input size="small" v-model.trim="userChangePasswordModel.newPassword" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <st-button color="gray" msg="取消" @click="userChagePasswordVisible = false" />
        <st-button msg="确定" @click="handleChangePasswordSave" />
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showContact"
      title="紧急联系方式"
      class="contact"
      width="50%"
      @close="showContact = false"
    >
      <div class="contact-warp">
        <div v-for="(e, i) in contactNo.options" :key="i">
          <h3>{{ e.title }}：</h3>
          <el-table
            :data="e.content"
            border
            :header-row-style="{ background: 'linear-gradient(180deg, #3c5669 0%, #28324d 100%)', color: '#c2c8cd' }"
            :cell-style="{ padding: '12px 0', color: '#c2c8cd' }"
            :row-style="{ background: '#445164' }"
          >
            <el-table-column prop="label" label="单位名称" width="300"></el-table-column>
            <el-table-column prop="value" label="电话号码"></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'
import { putModifySelfPassword } from '@/api/users'
import { destroy } from '@/api/headRing'
const sha256 = require('js-sha256').sha256

export default {
  data() {
    return {
      loginTitle: window.globalConfig.loginTitle,
      isdashBoard: true,
      contactNo: window.globalConfig.contactNo,
      showContact: false,
      userChagePasswordVisible: false,
      userChangePasswordModel: { newPassword: '', oldPassword: '' }
    }
  },
  components: { Hamburger },
  computed: {
    ...mapGetters(['avatar', 'name', 'realName']),
    pageTitle() {
      return this.$route.meta.title
    },
    getDatetime() {
      let date = new Date()
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      return y + '-' + MM + '-' + d
    }
  },
  watch: {
    $route: {
      handler(v) {
        this.isdashBoard = v.path === '/dashboard'
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
        //headRing销毁
        destroy()
      })
    },
    handleChangePassword() {
      this.userChagePasswordVisible = true
    },
    handleChangePasswordSave() {
      let data = Object.assign({}, this.userChangePasswordModel)
      if (data.oldPassword === '') {
        this.$message({ type: 'warning', message: '请输入原密码' })
      } else if (this.newPassword === '') {
        this.$message({ type: 'warning', message: '请输入新密码' })
      } else {
        data.newPassword = sha256(data.newPassword)
        data.oldPassword = sha256(data.oldPassword)
        putModifySelfPassword(data).then(res => {
          if (res.code === 200) {
            this.$message({ type: 'success', message: '密码修改成功，即将退出登录...', duration: 0 })
            this.userChagePasswordVisible = false
            setTimeout(() => {
              this.logout()
            }, 2000)
          } else {
            this.$message({ type: 'error', message: res.message })
          }
        })
      }
    },
    handleUserChangePasswordDialogClose() {
      this.userChangePasswordModel = { newPassword: '', oldPassword: '' }
    },

    // 工作台收起所有弹窗
    closeAllBox() {
      const { isTopBox } = this.$store.state.app.dashboard
      const dashboard = {
        isTopBox: !isTopBox,
        isRightBox: !isTopBox,
        hideChat1: isTopBox ? true : false,
        hideChat2: isTopBox ? true : false,
        hideChat3: isTopBox ? true : false
      }
      this.$store.commit('CHANGE_DASHBOARD', dashboard)
    }
  }
}
</script>

<style lang="scss" scoped>
.fl {
  display: flex;
  align-items: center;
}
.bg {
  background: linear-gradient(0deg, rgba(112, 116, 135, 0.74) 0%, rgba(35, 43, 62, 0.74) 100%);
}
.dashboard-header {
  display: flex;
  justify-content: center;
  height: 58px;
  line-height: 58px;
  background: url('@/assets/images/dashboard/header.png') no-repeat;
  background-size: 101% 200%;
  background-position: -6px 0px;
  .page-title {
    font-size: 30px;
    font-weight: 800;
    color: #fefefe;
    text-shadow: 0px -2px 2px rgba(0, 65, 78, 0.36);
  }
  .page-r {
    position: absolute;
    right: 16px;
  }
  .date {
    font-weight: 400;
    color: #b8cce7;
    margin-right: 16px;
  }
  .phone {
    color: #b8cce7;
    font-size: 22px;
    margin-right: 16px;
    cursor: pointer;
  }
  .con-img {
    margin-left: 16px;
    cursor: pointer;
  }
  .avatar-container {
    height: 58px;
    display: flex;
    align-items: center;
    .avatar-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .user-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      .iser-name {
        line-height: 45px;
        margin: 0 10px;
        color: #b8cce7;
      }
      .uicon {
        color: $btn-bg-color;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 13px;
        font-size: 12px;
      }
    }
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
  height: 58px;
  line-height: 58px;
  background: $header-bg-color;
  padding: 0 16px;
  .page-title {
    display: flex;
    align-items: center;
    color: #fff;
    .picon {
      font-size: 20px;
      margin-right: 6px;
    }
    .menu {
      text-shadow: 0 0 10px rgb(68, 217, 236), 0 0 30px rgb(84, 203, 247), 0 0 50px rgb(32, 180, 230),
        0 0 70px rgb(23, 162, 231), 0 0 90px rgb(19, 125, 224), 0 0 110px rgb(7, 80, 122),
        0 0 130px rgba(14, 62, 158, 1);
    }
  }
  .hamburger-container {
    flex: 1;
    line-height: 58px;
    height: 58px;
    float: left;
    padding: 0 26px 0 15px;
  }
  .avatar-container {
    height: 58px;
    display: flex;
    align-items: center;
    .avatar-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .iser-name {
        line-height: 45px;
        margin: 0 10px;
        color: #fff;
      }
      .uicon {
        color: $btn-bg-color;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 13px;
        font-size: 12px;
      }
    }
  }

  .phone {
    color: $btn-bg-color;
    font-size: 22px;
    cursor: pointer;
  }
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

.contact {
  ::v-deep .el-dialog {
    .el-dialog__title {
      font-size: 24px;
    }
    .el-dialog__body::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 30px;
      width: 12px;
      height: 3px;
      background-color: #fff;
    }
  }
}
.contact-warp {
  min-height: 100px;
  color: $color;
  overflow: auto;
  max-height: 50vh;
  width: 60%;
  margin: 0 auto;
  h3 {
    font-size: 18px;
  }

  ::v-deep .el-table--border {
    border: 1px solid #5e697c;
    .el-table__cell {
      border-right: 1px solid #5e697c;
    }
    &::after {
      background-color: #5e697c;
    }
  }
}

@media screen and (max-width: 1440px) {
  .contact-warp {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .contact-warp {
    width: 100%;
  }
}
</style>
