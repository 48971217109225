import request from '@/utils/request'
import { sha256 } from 'js-sha256'

/**
 * 登录接口
 * @param username 用户名 必传
 * @param password 密码  必传
 * @param isSha 密码是否加密  非必传 默认false：密码加密； true：密码不加密
 * @returns {*}
 */
export function login(username, password, autoLogin = false, isSha = false) {
  if (!isSha) password = sha256(password)
  return request({ url: '/login', method: 'post', data: { username, password, autoLogin } })
}

export function getInfo(params) {
  return request({ url: 'user/getuserinfo/:orgId/:username', method: 'get', params: params })
}

export function logout() {
  return request({ url: '/logout', method: 'get' })
}
