import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import { EventBus } from './plugins/EventBus'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import '@/icons' // icon
import '@/permission' // permission control
import '@/assets/css/iconfont.css'

import '@/styles/index.scss' // global css
import './assets/fonts/font.css'
// 引入openlayers样式
import 'ol/ol.css'

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(EventBus)

import '@/components/selfComponents'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
