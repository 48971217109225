<template>
  <div style="display: flex">
    <section class="app-main">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </section>
    <div v-show="false">
      <audio ref="audioAllDom" controls autoplay></audio>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'
import { initWebSocket } from '@/utils/ws'
import { mapGetters } from 'vuex'
import mp3 from '@/assets/video/14184.mp3'
import { config } from '@/utils/config'
import { throttle } from '@/utils'
import { onReportEvent, onSOSEvent, init, callPtt, destroy } from '@/api/headRing'
import { formatDayTime } from '@/utils/date'

export default {
  name: 'AppMain',
  data() {
    return {
      minHeight: window.innerHeight - 60,
      illegalEventInfo: [],
      jobInfo: [],
      eventInfo: [],
      illegalTypes: config.illegalType,
      socket: null,
      stompClient: null,
      duration: window.globalConfig.notify.duration,
      enable: window.globalConfig.notify.enable,
      warningDuration: window.globalConfig.warning.duration,
      audio: null,
      warningAlarmTimer: null
    }
  },
  computed: {
    ...mapGetters([
      'illegalEventInfoSock',
      'illegalEventInfoSockType',
      'deviceGasData',
      'talkGroupNumber',
      'hRtcDispatch'
    ])
  },
  watch: {
    illegalEventInfoSock(newV) {
      this.handleIllegalEventInfo(newV, this.illegalEventInfoSockType)
      this.playAlarmAudio()
    },
    deviceGasData(newV) {
      this.handleDeviceInfo(newV)
    },
    hRtcDispatch(newV) {
      if (newV) {
        init(this, this.hRtcDispatch)
      } else {
        //headRing销毁
        destroy()
      }
    },

    talkGroupNumber(newV) {
      if (!newV) {
        return
      }
      let callId = callPtt(newV, this.$refs.audioAllDom, this.callPttCallback)
      store.commit('SET_CALL_ID', callId)
    }
  },
  created() {
    initWebSocket()
    // var time = Math.floor(Math.random() * 41) + 60;
    // setInterval(() => {
    //   this.handleJobInfo()
    //   time = Math.floor(Math.random() * 41) + 60;
    // }, time * 1000)
  },
  mounted() {
    //智能头环初始化
    // this.hRtcDispatch = getHRtcDispatch()
    if (this.hRtcDispatch) {
      init(this, this.hRtcDispatch)
    }
    let _this = this
    this.audio = new Audio()
    this.audio.src = mp3

    this.$nextTick(() => {
      _this.audio.addEventListener('ended', () => {
        _this.audio.play()
      })
    })
    window.addEventListener('resize', this.setResize)

    //智能头环SOS报警
    onSOSEvent((from, result) => {
      console.log('来自', from, result)
      this.handelehRingSOS(from, JSON.parse(result))
      this.playAlarmAudio()
      store.dispatch('GetHRingSOSData', result)
    })
    //图传记录
    onReportEvent(from => {
      this.handeleUploadPictures(from)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setResize)
  },
  methods: {
    playAlarmAudio() {
      //触发告警音频播放
      this.audio.currentTime = 0
      this.audio.play()

      if (this.warningAlarmTimer) {
        clearInterval(this.warningAlarmTimer)
      }
      let _this = this
      this.warningAlarmTimer = setTimeout(() => {
        _this.audio.pause()
      }, this.warningDuration)
    },

    //全局监听对讲状态的回调函数
    callPttCallback(callId, from, state, stateNumber, members) {
      if (members) store.commit('SET_MEMBERS', members)
      store.commit('SET_MEMBER_STATE', {
        state,
        stateNumber,
        from
      })
    },
    // hangupPttCallback(callId, caller) {
    //   console.log(callId, caller)
    // },
    handleIllegalEventInfo(data, level) {
      const that = this
      const { unreadCount, msgInfoId, title, content } = data
      store.commit('SET_ILL_TOTAL', unreadCount)
      if (this.enable === false) return
      this.$notify({
        title: title,
        dangerouslyUseHTMLString: true,
        message: content,
        customClass: 'notifyClass',
        type: level,
        position: 'bottom-right',
        onClick: function () {
          that.$router.push(`/msg/list/detail/${msgInfoId}`)
          this.close()
        },
        duration: that.duration
      })
    },
    //处理图传信息
    handeleUploadPictures(from) {
      const that = this
      let html = `<p>来自${from}的上报</p>`
      this.$notify({
        title: '上报快照',
        dangerouslyUseHTMLString: true,
        message: html,
        customClass: 'notifyClass',
        type: 'warning',
        position: 'bottom-right',
        onClick: function () {
          that.$router.push({ path: '/illegal/uploadPictures' })
          this.close()
        },
        duration: this.duration
      })
    },
    //SOS报警弹窗
    handelehRingSOS(from, result) {
      let time = formatDayTime(+result.time)
      const that = this
      let html = `<p>来自${from}的SOS报警</p><p>报警时间: ${time}</p>`
      this.$notify({
        title: '智能头环SOS报警',
        dangerouslyUseHTMLString: true,
        message: html,
        customClass: 'notifyClass',
        type: 'error',
        position: 'bottom-right',
        onClick: function () {
          that.$router.push({ name: 'intellectHeadRing', params: result })
          this.close()
        },
        duration: 0
      })
    },
    handleJobInfo(data, level) {
      const that = this
      let msg = '测试作业有新的项目进度，请关注'
      this.$notify({
        title: '作业消息',
        message: msg,
        customClass: 'notifyClass',
        type: level,
        onClick: function () {
          that.$router.push({ path: '/jobManagement/JobDetails', query: { id: '1' } })
          this.close()
        },
        duration: 6000
      })
    },
    handleDeviceInfo(data) {
      const that = this
      this.$notify({
        title: data.title,
        customClass: 'notifyClass',
        type: 'error',
        message: data.content,
        onClick: function () {
          that.$router.push({ path: '/illegal/eventDetails', query: { eventId: data.illegalId } })
          this.close()
          store.dispatch('GetEventId', data.illegalId)
        }
        // duration: 0
      })
    },
    setResize() {
      throttle(() => {
        this.minHeight = window.innerHeight - 60
      }, 10)
    }
  }
}
</script>

<style>
.app-main {
  position: relative;
  padding: 16px;
  background: url('@/assets/images/background.png') no-repeat;
  background-size: 100% 100%;
  min-height: calc(100vh - 58px);
  width: 100%;
  overflow: auto;
}
.notifyClass {
  cursor: pointer;
}
.el-notification {
  width: 340px;
}
</style>
