import request from '@/utils/request'

// const rtcInfo = {
//   serverAddr: hRtcDispatch?.serverAddr,
//   wsPort: hRtcDispatch?.wsPort,
//   account: hRtcDispatch?.account,
//   password: hRtcDispatch?.password,
//   isSSL: hRtcDispatch?.isSsl,
//   httpPort: hRtcDispatch?.httpPort
// }

let rtcClient
let instance
/**
 * 智能头环调度台实例初始化，系统账号登录时用于自动登录调度台到头环server，在系统账号退出时需要调用destroy方法销毁此实例
 */
export function init(vInstance, hRtcDispatch) {
  instance = vInstance
  // eslint-disable-next-line no-undef
  rtcClient = new SIP.Web.RtcClient(
    hRtcDispatch.serverAddr,
    hRtcDispatch.wsPort,
    hRtcDispatch.account,
    hRtcDispatch.password,
    rtcClientCallback,
    hRtcDispatch.isSsl,
    hRtcDispatch.httpPort
  )
  rtcClient.login()
}

/**
 * 智能头环调度台实例销毁
 */
export function destroy() {
  if (!rtcClient) return
  rtcClient.logout()
}

let resolveFetchDeviceTree
let resolveFetchOnlineStatus = {
  source: []
}
/**
 * 获取智能头环设备树
 */
// export async function fetchDeviceTree() {
//   return await new Promise((resolve, reject) => {
//     try {
//       rtcClient.loadUsers()
//       resolveFetchDeviceTree = resolve
//     } catch (err) {
//       reject(err)
//     }
//   })
// }

/**
 * 获取指定设备的实时视频地址
 * @param number
 * @returns {Promise<void>}
 */
let resolveStartLiveMonitor
let rejectStartLiveMonitorError
// export async function startLiveMonitor(number) {
//   return await new Promise((resolve, reject) => {
//     try {
//       rtcClient.startVideoMonitor(rtcInfo.account, number)
//       resolveStartLiveMonitor = resolve
//       rejectStartLiveMonitorError = reject
//     } catch (e) {
//       reject(e)
//     }
//   })
// }

/**
 * 获取智能头环的推流地址
 * @param params.usrNumber 设备的序列号，智能头环设备传入设备号码
 * @returns {*}
 */
export function getHRingLive(params) {
  return request({
    url: '/ris/live/hrtc/live',
    method: 'get',
    params
  })
}

/**
 * 获取智能头环定位
 * @param params
 * @param params.number string  要查询位置的智能头环号码，多个用逗号分隔，不传为查所有
 */
export function getLocation(params) {
  return request({
    url: '/hrtc/location',
    method: 'get',
    params
  })
}

/**
 * 获取智能头环SOS报警记录
 * @param params.beginTime String  时间范围起始时间，格式：2017-06-01
 * @param params.endTime String  时间范围结束时间，格式：2017-06-01
 * @param params.enterpriseUuid String  enterpriseUuid
 * @param params.order String  排序方式，desc:降序；asc:升序；默认升序
 * @param params.orderBy String  排序字段，默认为sostime
 * @param params.page String  分页参数，获取第几页的数据
 * @param params.size String  分页参数，每页条数
 * @param params.sosId String
 * @returns {*}
 */
export function getHRingSOSList(params) {
  return request({
    url: '/hrtc/sos',
    method: 'get',
    params
  })
}

/**
 * 获取图传记录
 * @param params.begin String 时间范围查询，起始时间
 * @param params.end string 时间范围查询，结束时间
 * @param params.keyword string 媒体文件描述内容/上传人id
 * @param params.page string 分页参数,第几页
 * @param params.size string 分页参数,每页多少条
 * @param params.state string
 * @param params.type string
 * @returns {*}
 */
export function getUploadPictureList(params) {
  return request({
    url: '/hrtc/report',
    method: 'get',
    params
  })
}

/**
 * 邀请某个成员加入对讲
 * @param params.callees string 	被邀请的号码
 * @param params.caller string 	发起邀请的号码
 * @param params.groupNumber string  被邀请加入的对讲所在的对讲组号码
 * @returns {*}
 * @constructor
 */
export function OnInvite(params) {
  return request({
    url: '/hrtc/ptt/invite',
    method: 'get',
    params
  })
}

/**
 * 将某个成员踢出对讲
 * @param params.groupNumber string 要踢出的对讲所在的对讲组号码
 * @param params.kickOutNumber string 要踢出的号码
 * @returns {*}
 * @constructor
 */
export function OnKickOut(params) {
  return request({
    url: '/hrtc/ptt/kickout',
    method: 'get',
    params
  })
}

/**
 * 停止指定设备的实时视频
 * @param number
 * @returns {Promise<unknown>}
 */
// export async function stopLiveMonitor(number) {
//   return await new Promise((resolve, reject) => {
//     try {
//       rtcClient.stopVideoMonitor(rtcInfo.account, number)
//       resolve('stop success')
//     } catch (e) {
//       reject(e)
//     }
//   })
// }

/**
 * 获取语音对讲组
 * @returns {Promise<unknown>}
 */
let resolveVoiceGroup
let rejectVoiceGroupError
// export async function fetchVoiceGroup() {
//   return await new Promise((resolve, reject) => {
//     try {
//       rtcClient.loadGroup()
//       resolveVoiceGroup = resolve
//       rejectVoiceGroupError = reject
//     } catch (e) {
//       reject(e)
//     }
//   })
// }

let callPttCallback
// let hangupPttCallback
export function callPtt(groupNumber, audioDom, callCb) {
  callPttCallback = callCb
  // hangupPttCallback = hangupCb
  let callId = rtcClient.makeGroupPtt(groupNumber, audioDom)
  return callId
}

let resolveRequestPtt
export async function requestPtt(callId, groupNumber) {
  return await new Promise(resolve => {
    //callId:对讲通话ID；groupNumber:对讲组号码；1:申请上麦/2:释放麦
    rtcClient.pttcontrol(callId, groupNumber, 1)
    resolveRequestPtt = resolve
  })
}

let resolveReleasePtt
export async function releasePtt(callId, groupNumber) {
  return await new Promise(resolve => {
    rtcClient.pttcontrol(callId, groupNumber, 2)
    resolveReleasePtt = resolve
  })
}

export function hangupPtt(callId, groupNumber) {
  rtcClient.hangup(callId, groupNumber)
}

let sosEventCallback
export function onSOSEvent(callback) {
  sosEventCallback = callback
}

let reportCallback
export function onReportEvent(callback) {
  reportCallback = callback
}

const rtcClientCallback = {
  onLoginSuccess: message => {
    console.log(message)
  },

  onLoginFailed: message => {
    instance.$message({
      message: message,
      type: 'error'
    })
  },

  onUserListEvent: (from, result) => {
    result = JSON.parse(result)
    fetchOnlineStatus().then(onlineStatus => {
      convertTreeNodeData(result, onlineStatus)
    })
  },

  onUserStatusEvent: (from, result) => {
    result = JSON.parse(result)
    let shift = resolveFetchOnlineStatus.source.shift()
    resolveFetchOnlineStatus[shift](result)
    delete resolveFetchOnlineStatus[shift]
  },

  onVideoMonitorEvent: (caller, callee, result) => {
    result = JSON.parse(result)
    switch (result.state) {
      case '200':
        resolveStartLiveMonitor(result.data)
        break
      case '404':
        rejectStartLiveMonitorError('当前设备不在线')
        break
      case '500':
        rejectStartLiveMonitorError('对方设备不存在')
        break
      default:
        rejectStartLiveMonitorError('未知错误')
    }
  },

  onGroupEvent: result => {
    result = JSON.parse(result)
    fetchOnlineStatus().then(onlineStatus => {
      convertVoiceGroupData(result, onlineStatus)
    })
  },

  //暂时不需要实现应答事件
  // onCallAnswered: (callId, callee) => {},

  /**
   * 挂断通话事件回调
   * @param callId 通话ID
   * @param caller 挂断人账号，如果为对讲，则为对讲组号码
   */
  onCallHangup: () => {
    // hangupPttCallback(callId, caller)
  },

  /**
   *对讲组状态事件回调
   *
   * @param callId 通话ID
   * @param from 对讲组号码
   * @param state 通话状态码：1：正在讲话；2：停止讲话；3：加入对讲组；4：退出对讲组；0：无人讲话，静默状态
   * @param stateNumbers 触发当前对讲事件的设备号码，用来标识当前状态变化来自哪个设备
   * @param members 当前组在线设备号码，多个用#分割
   * @param count 当前组设备数量
   */
  onPttEvent: (callId, from, state, stateNumber, members, count) => {
    callPttCallback(callId, from, state, stateNumber, members, count)
  },

  /**
   * ptt控制事件回调，申请上麦/下麦操作结果通知
   * @param callId 通话ID
   * @param from 对讲组号码
   * @param type 控制事件类型。1：申请上麦；2：申请下麦
   * @param result 操作结果。"1"：成功；"0"：失败
   */
  onPttControlEvent: (callId, from, type, result) => {
    switch (type) {
      case 1:
        resolveRequestPtt(result)
        break
      case 2:
        resolveReleasePtt(result)
        break
      default:
        console.log('onPttControlEvent 未知类型，', type)
    }
  },

  /**
   * 监听实时SOS信息
   * @param from sos 信息来源
   * @param result sos 信息
   */
  onSOSEvent: (from, result) => {
    sosEventCallback(from, result)
  },

  /**
   * 监听实时图传信息
   * @param from 图传信息来源
   * @param result 图传信息
   */
  onReportEvent: (from, result) => {
    reportCallback(from, result)
  },
  //收到呼叫请求，此处UI通知来电，可选择接听/拒绝
  //id:来电标识，用户后续挂断等操作，多路通话唯一标识
  //callee:来电人账号，用于关联通讯录联系人
  //isVideo:是否是视频来电
  //callType:0,普通双向呼叫，1：静默视频回传，需要自动应答，2：报警呼叫，需要自动应答
  //initiator: 呼叫发起人号码(语音会议和语音广播才有 )
  //members: 对接临时组来电，组内成员，以 # 隔开
  onCallReceived: (id, callee, isVideo, callType) => {
    if (callType === 2) {
      //拒接来电
      rtcClient.decline(id, callee)
    }
  }
}

async function fetchOnlineStatus() {
  //随机字符串
  let source = +new Date() + ''
  return await new Promise(resolve => {
    try {
      rtcClient.loadUserStatus()
      resolveFetchOnlineStatus.source.push(source)
      resolveFetchOnlineStatus[source] = resolve
    } catch (err) {
      instance.$message({
        message: '内部错误:' + err,
        type: 'error'
      })
    }
  })
}

/**
 * 将原始数据转换为键值对集合
 * @param originData
 * @returns {[]}
 */
function convertTreeNodeData(originData, onlineStatus) {
  let onlineUsers = onlineStatus['users']
  let departments = []
  let departmentKeys = originData['fields']['department-fields']
  let departmentValues = originData['data']['departments']

  for (const dValues of departmentValues) {
    let departmentMap = {}
    for (const kIndex in departmentKeys) {
      departmentMap[departmentKeys[kIndex]] = dValues[kIndex]
    }
    departments.push(departmentMap)
  }

  let users = []
  let userKeys = originData['fields']['user-fields']
  let userValues = originData['data']['users']

  for (const uValues of userValues) {
    let userMap = {}
    userMap['isOnline'] = false
    for (const kIndex in userKeys) {
      userMap[userKeys[kIndex]] = uValues[kIndex]
      if (userKeys[kIndex] === 'usr_number') {
        for (const onlineUser of onlineUsers) {
          if (onlineUser === uValues[kIndex]) {
            userMap['isOnline'] = true
          }
        }
      }
    }
    users.push(userMap)
  }
  treeify(departments, users)
}
function treeify(departmentArray, userArray) {
  /*
   * 1、找根，dep_level长度为3的，可能存在多个根，至少会有1个根
   * 2、遍历剩余的，父级的dep_level长度=子级的dep_level长度-3；父级的dep_level=子级dep_level减去末三位
   * */
  let roots = []
  let subNodes = []
  for (const dElement of departmentArray) {
    let treeNode = {
      name: dElement['dep_name'],
      id: dElement['dep_uuid'],
      pid: '',
      depLevel: dElement['dep_level'],
      type: 'group' /* group, device */,
      totalDeviceNum: 0,
      onlineDeviceNum: 0,
      children: []
    }
    if (treeNode.depLevel.length === 3) {
      roots.push(treeNode)
    } else {
      subNodes.push(treeNode)
    }
  }

  let userNodes = []
  for (const uElement of userArray) {
    let treeNode = {
      name: uElement['usr_name'],
      id: uElement['usr_uuid'],
      pid: uElement['usr_dep_uuid'],
      usrNumber: uElement['usr_number'],
      usrType: uElement['usr_type'] /* dispatch, softphone */,
      type: 'device' /* group, device */,
      isOnline: uElement['isOnline']
    }
    userNodes.push(treeNode)
  }

  for (const root of roots) {
    caculDeviceNumber(root, userNodes)
    recurseCal(root, subNodes, userNodes)
  }

  resolveFetchDeviceTree(roots)
}

function recurseCal(root, subNodes, userNodes) {
  for (const subNode of subNodes) {
    if (
      subNode.depLevel.length === root.depLevel.length + 3 &&
      subNode.depLevel.substring(0, root.depLevel.length) === root.depLevel
    ) {
      caculDeviceNumber(subNode, userNodes)
      root.totalDeviceNum += subNode.totalDeviceNum
      root.onlineDeviceNum += subNode.onlineDeviceNum
      subNode.pid = root.id
      root.children.push(subNode)
    } else {
      let remainNodes = subNodes.filter(({ id }) => {
        id !== subNode.id
      })
      recurseCal(subNode, remainNodes, userNodes)
    }
  }
}

function caculDeviceNumber(groupNode, userNodes) {
  for (const userNode of userNodes) {
    if (userNode.pid === groupNode.id) {
      groupNode.children.push(userNode)
      groupNode.totalDeviceNum += 1
      if (userNode.isOnline) {
        groupNode.onlineDeviceNum += 1
      }
    }
  }
}

function convertVoiceGroupData(data, onlineUsersData) {
  if (data.code === '1') {
    //"1"代表成功拿到数据
    let result = []
    for (const group of data.value) {
      let groupNode = { isDefault: false, totalDeviceNum: 0, onlineDeviceNum: 0, children: [] }
      groupNode.groupId = group['group_uuid']
      groupNode.groupName = group['group_name']
      groupNode.groupType = group['call_grouptype']
      groupNode.groupNumber = group['group_hostextension']
      if (group['is_default'] === '1') {
        groupNode.isDefault = true
      }
      let onlineUsers = onlineUsersData['users']
      for (const user of group['users']) {
        let deviceNode = { isOnline: false }
        deviceNode.name = user['usr_name']
        deviceNode.id = user['uid']
        deviceNode.usrNumber = user['extension']
        deviceNode.usrType = user['usr_type']
        for (const onlineUser of onlineUsers) {
          if (onlineUser === deviceNode.usrNumber) {
            deviceNode.isOnline = true
            groupNode.onlineDeviceNum += 1
          }
        }
        groupNode.children.push(deviceNode)
      }
      groupNode.totalDeviceNum = groupNode.children.length
      result.push(groupNode)
    }
    resolveVoiceGroup(result)
  } else {
    rejectVoiceGroupError(data.msg)
  }
}

/**
 * 获取智能头环设备树
 * @returns {*}
 */
export function fetchDeviceTree(cancelSignal = null) {
  return request({
    url: '/hrtc/client/departments',
    method: 'get',
    signal: cancelSignal
  })
}

/**
 * 获取对讲组
 * @returns {*}
 */
export function fetchVoiceGroup(cancelSignal = null) {
  return request({
    url: '/hrtc/client/solidGroup',
    method: 'get',
    signal: cancelSignal
  })
}

/**
 * 获取智能头环系统用户
 * @param params.current Number 页码
 * @param params.size Number 页码
 * @returns {*}
 */
export function getDispatchList(params) {
  return request({
    url: '/hrtc/user/dispatch',
    method: 'get',
    params
  })
}

/**
 * 添加/删除调度台用户
 * @param data
 * @param isDispatch true添加用户,false删除用户
 * @returns {*}
 */
export function setUseDispatchAccount({ data, isDispatch }) {
  return request({
    url: `/sys/user/dispatch?isDispatch=${isDispatch}`,
    method: 'post',
    data
  })
}

/**
 * 获取所有调度台信息
 * @param params.current Number 页码
 * @param params.size Number 每页数量
 * @returns {*}
 */
export function getHrtcDispatchList(params) {
  return request({
    url: '/hrtc/dispatch/list_all',
    method: 'get',
    params
  })
}

/**
 * 添加调度台
 * @param data.serverName String 调度台名称
 * @param data.serverAddr String 调度台地址
 * @param data.wsPort String WS端口
 * @param data.account String 账户
 * @param data.password String 密码
 * @param data.isSsl String 是否开启SSL
 * @param data.httpPort String HTTP端口
 * @returns {*}
 */
export function addDispatch(data) {
  return request({
    url: '/hrtc/dispatch',
    method: 'post',
    data
  })
}

/**
 * 更新调度台调度台
 * @param data.serverName String 调度台名称
 * @param data.serverAddr String 调度台地址
 * @param data.wsPort String WS端口
 * @param data.account String 账户
 * @param data.password String 密码
 * @param data.isSsl String 是否开启SSL
 * @param data.httpPort String HTTP端口
 * @returns {*}
 */
export function editDispatchAccount(data) {
  return request({
    url: '/hrtc/dispatch',
    method: 'put',
    data
  })
}

/**
 * 获取调度台详情
 * @param id Number 调度台id
 * @returns {*}
 */
export function getDispatchDetail(id) {
  return request({
    url: `/hrtc/dispatch?id=${id}`,
    method: 'get'
  })
}

/**
 * 删除调度台
 * @param ids Array 调度台账户ids
 * @returns {*}
 */
export function deleteDispatch(ids) {
  return request({
    url: `/hrtc/dispatch?ids=${ids}`,
    method: 'delete'
  })
}

/**
 * 调度台绑定用户
 * @param id Number 调度台id
 * @param userId Number 用户id
 * @returns {*}
 */
export function bindDispatch({ id, userId }) {
  return request({
    url: `/hrtc/dispatch/binding?id=${id}&userId=${userId}`,
    method: 'post'
  })
}

/**
 * 调度解绑用户
 * @param id Number 调度台id
 * @returns {*}
 */
export function unbindDispatch(id) {
  return request({
    url: `/hrtc/dispatch/unbinding?id=${id}`,
    method: 'post'
  })
}
