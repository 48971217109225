import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import ws from './modules/ws'
import getters from './getters'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          username: val.user.name,
          avatar: val.user.avatar,
          realName: val.user.realName,
          activePath: val.user.activePath,
          accountType: val.user.accountType,
          logo: val.user.logo,
          smallLogo: val.user.smallLogo,
          hRtcDispatch: val.user.hRtcDispatch
        }
      }
    })
  ],
  modules: { app, user, ws },
  getters
})

export default store
