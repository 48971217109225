import request from '@/utils/request'
import { coHelper } from './messageCenter.serve'

// 设备列表 - 获取设备型号
export function getDeviceModel() {
  return request({ url: '/ter/device/device_model', method: 'get' })
}

// 设备列表 - 获取设备列表
export function getDeviceList(params) {
  return request({ url: '/ter/device/list_all', method: 'get', params })
}

// 设备列表 - 获取不分页设备列表
export function getDeviceListAll(params) {
  return request({ url: '/ter/device/list', method: 'get', params })
}

// 设备列表 - 保存设备
export function postSaveDevice(data) {
  return request({
    url: '/ter/device/save',
    method: 'post',
    data: data
  })
}

// 设备列表 - 根据id获取指定设备
export function getDeviceById(id) {
  return request({ url: '/ter/device/' + id, method: 'get' })
}

// 设备列表 - 根据id更新设备信息
export function putDeviceById(data, id) {
  return request({ url: '/ter/device/' + id, method: 'put', data })
}

// 设备列表 - 根据id删除指定设备
export function deleteDevice(id) {
  return request({ url: '/ter/device/' + id, method: 'delete' })
}

// 设备列表 - 根据id获取设备阈值信息
export function getDeviceConfigById(id) {
  return request({ url: '/ter/device/config/' + id, method: 'get' })
}

// 设备列表 - 批量修改
export function putBatchDevice(data) {
  return request({ url: '/ter/device/batch', method: 'put', data })
}

// 设备列表 - 批量删除
export function delBatchDevice(data) {
  return request({ url: '/ter/device/batch', method: 'delete', data })
}

// 设备列表 - 根据id设置设备阈值信息
export function putDeviceConfigById(data, id) {
  return request({ url: '/ter/device/config/' + id, method: 'put', data })
}

// 设备列表 - 获取设备详情
export function getDeviceDetail(id) {
  return request({ url: '/ter/device/detail/' + id, method: 'get' })
}

// 获取指定设备的直播流播放地址
export function getLiveAddress(deviceSerial) {
  return request({ url: '/device/getliveaddress/' + deviceSerial, method: 'get' })
}

// 获取指定设备的历史视频记录
export function getFileIds(params) {
  return request({ url: '/vod/getfileids', method: 'get', params })
}

// 获取设备图表信息和设备信息刷新频率
export function getFrequency() {
  return request({ url: '/getfrequency', method: 'get' })
}

// 获取设备图标信息和设备信息
export function getDeviceData(deviceSerial) {
  return request({ url: '/device/getdata/' + deviceSerial, method: 'get' })
}

// 获取指定设备直播情况
export function getLiveList(params) {
  return request({ url: '/ris/live/list', method: 'get', params })
}

// 设备组网
export function postTerNetCb(data) {
  return request({ url: '/ter/net/cb', method: 'post', data })
}

// 查看组网信息
export function getTerNetFind(params) {
  return request({ url: '/ter/net/find', method: 'get', params })
}

// 清除组网
export function postTerNetClear(data) {
  return request({ url: '/ter/net/clear', method: 'post', data })
}

// 获取设备升级列表
export function getDeviceUpdate(params) {
  return request({ url: '/ter/ota/list', method: 'get', params })
}

// 上传设备升级包
export function postDeviceUpdate(data) {
  return request({ url: '/ter/ota/upload', method: 'post', data })
}

// 发布更新包
export function putDeviceUpdate(id) {
  return request({ url: '/ter/ota/release?id=' + id, method: 'put' })
}

// 处理设备对焦
export function getDeviceCameraControl(controlType, serial) {
  let timestamp = new Date().getTime()
  return request({
    url: '/ter/device/cameraControl?serial=' + serial + '&controlType=' + controlType + '&timestamp=' + timestamp,
    method: 'get'
  })
}

// 云台控制方向
export function getDeviceDirectionControl(controlType, serial) {
  let timestamp = new Date().getTime()
  return request({
    url: '/ter/device/cameraControl?serial=' + serial + '&controlType=' + controlType + '&timestamp=' + timestamp,
    method: 'get'
  })
}

// 工作台地图坐标信息
export function getDeviceGeo() {
  return request({ url: '/ter/device/geo', method: 'get' })
}

// 获取需要订阅的 ws topic
export function getwsMessageTopic() {
  return request({ url: '/wsMessageTopic', method: 'get' })
}

/**
 * 获取区域树
 * @returns
 */
export function getGroupDeviceTree() {
  return request({ url: '/ter/device/tree', method: 'get' })
}

// 获取区域设备列表信息
export function getGroupDevice(params) {
  return request({ url: '/ter/device/list_all', method: 'get', params: params })
}

/**
 * 新增分区
 * @param {{
 *  regionName: string;
 *  pid: number;
 * }} data
 * @returns
 */
export function saveGroupOrDevice(data) {
  return request({ url: '/ter/device/tree', method: 'post', data: data })
}
/**
 * 删除区域
 * @param {{id: number}} params
 * @returns
 */
export function delGroupOrDevice(params) {
  return request({ url: '/ter/device/tree', method: 'delete', params })
}

// 更新区域设备
export function updateGroupOrDevice(data) {
  return request({ url: '/ter/device/tree', method: 'put', data: data })
}

// 划拨设备设备
export function updateGroupDeviceAllocate(data, params) {
  return request({
    url: '/ter/device/allocate?orgId=' + params.orgId + '&ownerId=' + params.ownerId,
    method: 'put',
    data: data
  })
}

// 获取工牌坐标信息
export function getBadgeGeo() {
  return request({ url: '/badge/lastGps', method: 'get' })
}

// 获取区域和直播树结构
export function getGroupAndDeviceTree() {
  return request({ url: '/ris/live/livesStream', method: 'get' })
}

// 历史视频
export function getVideoList(params) {
  return request({ url: '/ter/video/list', method: 'get', params: params })
}

// 人工辅助
export function getMonitor(params) {
  return request({ url: '/monitor/info', method: 'get', params: params })
}

// 推送结果提交
export function postMonitorData(data) {
  return request({ url: '/risk/webhook/manual/ai/illegalResult', method: 'post', data })
}

/**
 * 获取最新设备影像截图
 * @param {string} serialNo 设备序列号
 * @return {Promise}
 */
export async function getAiLineBackIamgeUrl(serialNo) {
  if (!serialNo) {
    return [new Error(0), null]
  }
  try {
    const result = await request({ url: `/ris/algorithm/poster/${serialNo}`, method: 'get' })
    if (result) {
      return [null, result]
    }
    return [new Error(1), null]
  } catch (error) {
    return [error, null]
  }
}

// ai服务列表服务
/**
 * 获取ai服务列表
 * @param {{
 *  serverAddress?: string;
 *  serverName?: string,
 *  enable?: boolean,
 *  dispatchStrategy?: string,
 *  current: number,
 *  size: number
 * }} params
 * @return {[ Error|null, any, any]}
 */
export async function getAiServerListServe(params) {
  return coHelper(() => request({ url: `/ai/ai_server/list`, method: 'get', params: params }))
}
/**
 * 获取可手动分派设备列表
 * @return {[ Error|null, any, any]}
 */
export async function getDeviceListServe() {
  return coHelper(() => request({ url: `/ai/ai_server/listDevice`, method: 'get' }))
}
/**
 * 获取可手动分派设备列表
 * @param {Array<string>} deviceIdArrayList
 * @return {[ Error|null, any, any]}
 */
export async function postDeviceStatusServe(deviceIdArrayList) {
  return coHelper(() => request({ url: `/ai/ai_server/checkStatus`, method: 'post', data: deviceIdArrayList }))
}
/**
 * 新增ai服务
 * @param {} formData
 * @return {[ Error|null, any, any]}
 */
export async function postAddAIServe(formData) {
  return coHelper(() => request({ url: `/ai/ai_server/add`, method: 'post', data: formData }))
}
/**
 * 更新ai服务
 * @param {} formData
 * @return {[ Error|null, any, any]}
 */
export async function postEditAIServe(formData) {
  return coHelper(() => request({ url: `/ai/ai_server/upd`, method: 'post', data: formData }))
}
/**
 * 删除ai服务
 * @param {Array<string|number>} deviceIds
 * @return {[ Error|null, any, any]}
 */
export async function postDeleteAIServe(deviceIds) {
  return coHelper(() => request({ url: `/ai/ai_server/del`, method: 'post', data: deviceIds }))
}
/**
 * 获取已分配的url
 * @param {{url: string}} params
 * @return {[ Error|null, any, any]}
 */
export async function getAiDetailServe(params) {
  return coHelper(() => request({ url: `/ai/ai_server/dispatchDevices`, method: 'get', params }))
}

// 设备黑名单服务
/**
 * 获取ai服务列表
 * @param {{
 *  blackSerialNo?: string;
 *  serialName?: string,
 *  regionId?: string,
 *  current: number,
 *  size: number
 * }} params
 * @return {[ Error|null, any, any]}
 */
export async function getBlackListServe(params) {
  return coHelper(() => request({ url: `/ter/device_black/blackList`, method: 'get', params: params }))
}
/**
 * 添加设备黑名单
 * @param { Array<string> } serialNoList
 * @return {[ Error|null, any, any]}
 */
export async function postAddBlackListServe(serialNoList) {
  return coHelper(() => request({ url: `/ter/device_black/addDeviceBlack`, method: 'post', data: serialNoList }))
}
/**
 * 删除设备黑名单
 * @param { Array<string> } serialNoList
 * @return {[ Error|null, any, any]}
 */
export async function postRemoveBlackListServe(serialNoList) {
  return coHelper(() => request({ url: `/ter/device_black/delDeviceBlack`, method: 'post', data: serialNoList }))
}
