import request from '@/utils/request'
import { coHelper } from './messageCenter.serve'

// 账户管理 - 获取用户列表
export function getUserList(params) {
  return request({
    url: '/sys/user/list_all',
    method: 'get',
    params: params
  })
}

/**
 * 获取账户离线超时设置阀值
 * @returns {Promise<{data:{result:{threshold: number, interval: number}}}>}
 */
export function getOffLine() {
  return request({
    url: '/sys/user/offLine',
    method: 'get'
  })
}

/**
 * 设置账户离线超时设置阀值
 * @param {{threshold: number}} params
 * @param {{interval: number}} params
 * @returns {Promise<{data:{code:number,message:string}}>}
 */
export function setOffLine(params) {
  return request({
    url: '/sys/user/offLine',
    method: 'put',
    params: params
  })
}

/**
 * 获取告警短信接收人列表
 * params.userId number 用户id
 * params.current number 当前页
 * params.size number 每页多少条数据
 * @returns {*}
 */
export function getAlarmRecipientList(params) {
  return request({
    url: '/event/receive/user',
    method: 'get',
    params
  })
}

/**
 * 修改告警短信接收人信息
 * @param data.id number id
 * @param data.receiveName String 接收人姓名
 * @param data.mobilePhone String 手机号
 * @returns {*}
 */
export function setAlarmRecipient(data) {
  return request({
    url: '/event/receive/user',
    method: 'put',
    data
  })
}

/**
 * 删除告警短信接收人信息
 * @param id String 用户id
 * @returns {*}
 */
export function deleteAlarmRecipient(id) {
  return request({
    url: `/event/receive/user?id=${id}`,
    method: 'delete'
  })
}

export function deleteLargeAlarmRecipient(params) {
  return request({
    url: `/event/receive/user/batch`,
    method: 'delete',
    data: params
  })
}

/**
 * 新建警短信接收人信息
 * @param data.receiveName String 接收人姓名
 * @param data.mobilePhone String 手机号
 * @returns {*}
 */
export function addAlarmRecipient(data) {
  return request({
    url: '/event/receive/user',
    method: 'post',
    data
  })
}

/**
 * 获取监控账号列表
 * @param params.current number 页码
 * @param params.size number 每页数量
 * @returns {*}
 */
export function getAccountMonitoringList(params) {
  return request({
    url: '/sys/user/monitor',
    method: 'get',
    params
  })
}

/**
 * 添加用户离线监控管理 & 删除离线监控用户
 * @param isMonitor Boolean true表示添加监控用户 false表示删除监控用户
 * @param data.ids Array 用户ids数组
 * @returns {*}
 */
export function setAccountMonitoring(isMonitor, data) {
  return request({
    url: `/sys/user/monitor?isMonitor=${isMonitor}`,
    method: 'post',
    data
  })
}

// 账户管理 - 获取用户列表（组织机构设置管理员用, 设备分配设置管理员用）
export function getUserAll(params) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params: params
  })
}

// 账户管理 - 保存用户信息
export function postUserSave(data) {
  return request({
    url: '/sys/user/save_one',
    method: 'post',
    data: data
  })
}

// 账户管理 - 根据id获取账户信息
export function getUserById(id) {
  return request({
    url: '/sys/user/detail/' + id,
    method: 'get'
  })
}

// 账户管理 - 锁定账户
export function postUserLock(data) {
  return request({
    url: '/sys/user/lock',
    method: 'post',
    data: data
  })
}

// 账户管理 - 激活账户
export function postUserActive(data) {
  return request({
    url: '/sys/user/active',
    method: 'post',
    data: data
  })
}

// 账户管理 - 修改密码
export function putModifyPassword(data) {
  return request({
    url:
      '/sys/user/modifyPassword?id=' +
      data.id +
      '&newPassword=' +
      data.newPassword +
      '&superPassword=' +
      data.superPassword,
    method: 'put'
  })
}

// 账户管理 - 修改用户个人密码
export function putModifySelfPassword(data) {
  return request({
    url: '/sys/user/self/modifyPassword?newPassword=' + data.newPassword + '&oldPassword=' + data.oldPassword,
    method: 'put'
  })
}

export function putUserById(data) {
  return request({
    url: '/sys/user/userinfo',
    method: 'put',
    data: data
  })
}

// 账户管理 - 根据id删除账户信息
export function deleteUserById(id) {
  return request({
    url: '/sys/user/' + id,
    method: 'delete'
  })
}

// 上传用户头像
export function uploadpics(data, userId) {
  return request({
    url: '/sys/user/upload_avatar?userId=' + userId,
    method: 'post',
    data: data
  })
}

// 获取用户权限
export function getUserAuthority() {
  return request({
    url: '/index',
    method: 'get'
  })
}

/**
 * 获取在离线历史统计
 * @param {{
 * current: number | string;
 * size: number | string;
 * username?: string;
 * startTime?: string;
 * endTime?: string;
 * realName?: string;
 * onlineStatus?: ONLINE | OFFLINE;
 * }} params 请求参数
 * @returns {Promise<[Error | null, any, any]>}
 */
export function getLoginStatusServe(params) {
  return coHelper(() => request({ url: '/sys/login_status/queryList', method: 'get', params: params }))
}
