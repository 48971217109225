import Cookies from 'js-cookie'

const TokenKey = 'loginToken'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getAccessToken() {
  return Cookies.get('accessToken')
}

export function getUsername() {
  return Cookies.get('username')
}

export function getAccountType() {
  return Cookies.get('accountType')
}

export function getRealName() {
  return Cookies.get('realName')
}

export function getwsMessageTopic() {
  return Cookies.get('wsMessageTopic')
}

export function getOrgId() {
  return Cookies.get('orgId')
}

export function getUserAvatar() {
  return Cookies.get('avatar')
}

export function getLogo() {
  return Cookies.get('logo')
}

export function getSmallLogo() {
  return Cookies.get('smallLogo')
}

export function setToken(token, name) {
  let TokenName = name || TokenKey
  return Cookies.set(TokenName, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeAvatar() {
  return Cookies.remove('avatar')
}

// 记住密码
export function rememberAccount(value) {
  Cookies.set('stAccount', value.account, { expires: 7 })
  Cookies.set('stPassWord', value.password, { expires: 7 })
}
export function getRememberAccount() {
  return Cookies.get('stAccount')
}
export function getRememberPassword() {
  return Cookies.get('stPassWord')
}
export function removeRememberPassWord() {
  Cookies.remove('stAccount')
  Cookies.remove('stPassWord')
}
export function getHRtcDispatch() {
  if (Cookies.get('hRtcDispatch')) {
    return JSON.parse(Cookies.get('hRtcDispatch'))
  } else {
    return ''
  }
}
export function removeHRtcDispatch() {
  Cookies.remove('hRtcDispatch')
}
