import request from '@/utils/request'
export const coHelper = async func => {
  try {
    const response = await func()
    if (response.code === 200 && response.result != undefined) {
      return [null, response.result, response]
    }
    return [new Error(response.message), null, response]
  } catch (error) {
    if (error instanceof ReferenceError || error instanceof TypeError) {
      throw error
    }
    return [error, null, null]
  }
}
export const UP_LOAD_FILE_URL = `${request.defaults.baseURL}/storage/file/save`
/**消息列表 */
export function getMsgTypes() {
  return coHelper(() => request({ url: '/msg/msg_info/msgTypes', method: 'get' }))
}
export function getChannelTypes() {
  return coHelper(() => request({ url: '/msg/msg_info/channelTypes', method: 'get' }))
}
export function getMsgList(params) {
  return coHelper(() => request({ url: '/msg/msg_info/queryList', method: 'get', params }))
}
export function getDetail(msgId) {
  return coHelper(() => request({ url: `/msg/msg_info/detail/${msgId}`, method: 'get' }))
}
export function getGraft(params) {
  return coHelper(() => request({ url: `/msg/msg_info/graft`, method: 'get', params }))
}
export function postSaveGraft(data) {
  return coHelper(() => request({ url: `/msg/msg_info/saveGraft`, method: 'post', data }))
}
export function postMsgPublish(data) {
  return coHelper(() => request({ url: `/msg/msg_info/publish`, method: 'post', data }))
}
export function postDeleteMessage(data) {
  return coHelper(() => request({ url: `/msg/msg_info/delete`, method: 'post', data }))
}
export function getAllUnreadCount() {
  return coHelper(() => request({ url: `/msg/msg_info/getAllUnreadCount`, method: 'get' }))
}
export function postRemarkReadStatus(data) {
  return coHelper(() => request({ url: `/msg/msg_info/remarkReadStatus`, method: 'post', data }))
}
/**消息接收人 */
export function getUserList(params) {
  return coHelper(() => request({ url: `/msg/msg_recipient/list`, method: 'get', params }))
}
export function postAddUser(data) {
  return coHelper(() => request({ url: `/msg/msg_recipient/add`, method: 'post', data }))
}
export function postEditUser(data) {
  return coHelper(() => request({ url: `/msg/msg_recipient/edit`, method: 'post', data }))
}
export function deleteUser(data) {
  return coHelper(() => request({ url: `/msg/msg_recipient/delete`, method: 'delete', data }))
}
/** 文件上传 */
export function postSaveFile(data) {
  return coHelper(() => request({ url: UP_LOAD_FILE_URL, method: 'post', data }))
}
