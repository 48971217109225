<template>
  <div class="navbar-btn-layout">
    <div style="display: flex" @mouseenter="onMouseenter">
      <el-popover placement="bottom" width="260" trigger="hover">
        <el-card class="custom-card" :body-style="customCardStyle" shadow="never">
          <div class="tip-wrap">
            <div slot="header" class="header">
              <span>消息通知</span>
              <el-button @click="onToConfig" type="text">消息接收管理</el-button>
            </div>
            <div class="msg-list" v-loading="loading">
              <template v-if="total > 0">
                <div
                  @click="onToDetail(msg.msgStatusId)"
                  class="msg-item"
                  v-for="msg in all_list"
                  :key="msg.msgStatusId"
                >
                  <p class="subject">{{ msg.subject }}</p>
                  <p class="sendTime">{{ msg.sendTime }}</p>
                </div>
              </template>
              <el-empty v-else :image-size="30"></el-empty>
            </div>
            <div class="handle-container">
              <el-button type="text" @click="onReadAll">全部已读</el-button>
              <el-button type="text" @click="onReadMore">
                查看更多
                <i class="el-icon-d-arrow-right"></i>
              </el-button>
            </div>
          </div>
        </el-card>
        <el-button slot="reference" type="text">
          <el-badge :value="total" class="item"><i class="el-icon-message-solid"></i></el-badge>
        </el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { getMsgList, postRemarkReadStatus } from '@/api/messageCenter.serve'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'hamburger',
  data() {
    return {
      all_list: [],
      loading: false,
      customCardStyle: {
        padding: '0'
      }
    }
  },
  computed: {
    ...mapGetters(['total'])
  },
  created() {
    this.VuexActionSetTotal()
  },
  methods: {
    ...mapActions({
      VuexActionSetTotal: 'GetIllTotal'
    }),
    async getMsgListByServe() {
      const [error, result] = await getMsgList({ current: 1, size: 5, isRead: false })
      if (error) {
        return
      }
      this.all_list = result.records || []
    },
    async onMouseenter() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        await Promise.allSettled([this.getMsgListByServe(), this.VuexActionSetTotal()])
      } finally {
        this.loading = false
      }
    },
    async markReadStatus(requsetData) {
      const [error, status] = await postRemarkReadStatus(requsetData)
      if (error || !status) {
        const message = error ? error.message : '修改失败'
        return this.$message({
          message: message,
          type: 'warning'
        })
      }
      this.$message({
        message: '修改成功',
        type: 'success'
      })
      this.$EventBus.$emit('markAllReadStatus')
      /**
       * 标记已读时，更新全局未读信息总数
       */
      this.VuexActionSetTotal()
    },
    onToConfig() {
      this.$router.push('/msg/config')
    },
    onToDetail(msgId) {
      this.$router.push(`/msg/list/detail/${msgId}`)
    },
    onReadMore() {
      this.$router.push('/msg/list')
    },
    onReadAll() {
      this.$confirm('是否标记全部已读？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.markReadStatus({ readMsgStatusIdList: [-1] })
      })
    }
  }
}
</script>
<style>
body .el-popover.el-popper {
  padding: 0;
  border: 1px solid #bcbcbc;
  background-color: #445164;
}
</style>
<style scoped lang="scss">
.custom-card {
  overflow: hidden;
  background-color: #445164;
}
.tip-wrap {
  display: flex;
  height: 400px;
  flex-direction: column;
  background-color: #445164;
  .msg-list {
    color: #fff;
    flex: 1;
    padding: 0 10px;
    overflow: auto;
  }
  .header {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #bcbcbc;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .msg-item {
    padding: 5px 0;
    border-bottom: 1px dashed #d8d8d8;
    cursor: pointer;
    .subject {
      line-height: 30px;
      color: #fff;
      font-size: 14px;
      margin: 0;
    }
    .sendTime {
      line-height: 20px;
      color: #bcbcbc;
      font-size: 12px;
      margin: 0;
    }
  }
}
.navbar-btn-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 100px;
}
.handle-container {
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
