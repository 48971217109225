<template>
  <el-button
    :icon="icon"
    :size="size"
    :loading="loading"
    :nativeType="nativeType"
    :disabled="disabled"
    :plain="plain"
    :autofocus="autofocus"
    :round="round"
    :circle="circle"
    @click="handleClick"
    :class="color"
  >
    {{ msg }}
  </el-button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue',
      validator: function (value) {
        return ['blue', 'gray', 'orange'].indexOf(value) !== -1
      }
    }, // button颜色：blue、grey、orange
    msg: String, // 文字
    size: { type: String, default: 'medium' },
    icon: { type: String, default: '' },
    nativeType: { type: String, default: 'button' },
    loading: Boolean,
    disabled: Boolean,
    plain: Boolean,
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean
  },
  data() {
    return {}
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    }
  }
}
</script>

<style scoped lang="scss">
.el-button {
  background: none;
  border: none;
}

.blue {
  box-shadow: inset 0px 0px 8px 0px rgba(33, 232, 246, 1);
  ::v-deep span,
  ::v-deep i {
    color: rgba(33, 232, 246, 1);
  }
  &:hover {
    background: none;
    box-shadow: inset 0px 0px 26px 0px rgba(33, 232, 246, 1);
  }
}

.gray {
  box-shadow: inset 0px 0px 8px 0px rgba(198, 214, 215, 1);
  ::v-deep span,
  ::v-deep i {
    color: rgba(198, 214, 215, 1);
  }
  &:hover {
    background: none;
    box-shadow: inset 0px 0px 26px 0px rgba(198, 214, 215, 1);
  }
}

.orange {
  box-shadow: inset 0px 0px 8px 0px rgb(252, 173, 38);
  ::v-deep span,
  ::v-deep i {
    color: rgba(252, 173, 38, 1);
  }
  &:hover {
    background: none;
    box-shadow: inset 0px 0px 26px 0px rgba(252, 173, 38, 1);
  }
}
</style>
