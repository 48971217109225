export function formatTree(data) {
  let menuList = []
  data.forEach(item => {
    if (item.funcName !== '微信接口') {
      if (item.type === 1) {
        menuList.push({
          fid: item.id,
          funcPid: item.pid,
          name: item.component,
          path: item.component,
          weight: item.sort,
          level: item.level,
          meta: { title: item.name, icon: item.icon },
          hidden: item.hidden,
          openType: item.openType
        })
      }
    }
  })
  // 排序
  let min
  for (let i = 0; i < menuList.length; i++) {
    for (let j = i; j < menuList.length; j++) {
      if (menuList[i].weight === menuList[j].weight) {
        if (menuList[i].fid > menuList[j].fid) {
          min = menuList[j]
          menuList[j] = menuList[i]
          menuList[i] = min
        }
      } else {
        if (menuList[i].weight > menuList[j].weight) {
          min = menuList[j]
          menuList[j] = menuList[i]
          menuList[i] = min
        }
      }
    }
  }
  let cloneData = JSON.parse(JSON.stringify(menuList)) // 对源数据深度克隆
  let tree = cloneData.filter(father => {
    //循环所有项
    let branchArr = cloneData.filter(child => {
      if (child.path) {
        return father.fid === child.funcPid //返回每一项的子级数组
      }
    })
    if (branchArr.length > 0) {
      father.children = branchArr //如果存在子级，则给父级添加一个children属性，并赋值
    }
    return father.level === 1 //返回第一层
  })
  return tree //返回树形数据
}
