import Vue from 'vue'
import Router from 'vue-router'
/**
 * 重写路由的push方法
 * 解决重复点击相同路由，控制台报错，但不影响正常使用，问题出现在vue-router v3.1.x版本中
 * 问题详情：
 *  vue-router.esm.js?199e:2005
 *  Uncaught (in promise)
 *    NavigationDuplicated{_name: "NavigationDuplicated", name: "NavigationDuplicated"}
 *
 * 作为兼容性处理保留
 */
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
import { EXT_PAGE } from '@/utils/constant'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/bind',
    component: () => import('@/views/bind/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/help',
    component: () => import('@/views/help'),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: { title: '工作台', icon: '&#xe834;' }
      },
      {
        path: EXT_PAGE,
        name: 'extPage',
        component: () => import('@/views/extPage/index'),
        meta: { icon: '&#xe834;' }
      }
    ]
  },
  {
    path: '/statistics',
    component: Layout,
    redirect: '/parkStatistics',
    name: 'statistics',
    meta: { title: '统计中心', icon: '&#xe721;' },
    children: [
      {
        path: 'parkStatistics',
        name: 'park',
        component: () => import('@/views/statistics/park/index'),
        meta: { title: '园区统计中心', icon: '&#xe721;' }
      },
      {
        path: 'companyStatistics',
        name: 'company',
        component: () => import('@/views/statistics/company/index'),
        meta: { title: '企业统计中心', icon: '&#xe721;' }
      },
      {
        path: 'illegalStatistics',
        name: 'illegal',
        component: () => import('@/views/statistics/illegal/index'),
        meta: { title: '风险统计中心', icon: '&#xe721;' }
      },
      {
        path: 'Risk',
        name: 'Risk',
        component: () => import('@/views/statistics/Risk/index'),
        meta: { title: '风险统计中心', icon: '&#xe721;' }
      }
    ]
  },
  {
    path: '/deviceManagement',
    component: Layout,
    redirect: '/deviceManagement/device',
    name: 'deviceManagement',
    meta: { title: '设备管理', icon: '&#xe71a;' },
    children: [
      {
        path: 'device',
        name: 'device',
        component: () => import('@/views/deviceManagement/device/index'),
        meta: { title: '设备列表', icon: '&#xe71a;' }
      },
      {
        path: 'intellectHeadRing',
        name: 'intellectHeadRing',
        component: () => import('@/views/deviceManagement/intellectHeadRing/index'),
        meta: { title: '智能头环', icon: '&#xe71a;' }
      },
      {
        path: 'deviceDetails',
        name: 'deviceDetails',
        component: () => import('@/views/deviceManagement/device/details'),
        meta: {
          title: '设备详情',
          icon: 'dots',
          isHidden: true,
          fatherPath: '/deviceManagement/device'
        },
        hidden: true
      },
      {
        path: 'deviceAllocation',
        name: 'deviceAllocation',
        component: () => import('@/views/deviceManagement/device/allocation'),
        meta: { title: '设备划拨', icon: 'dots', isHidden: false },
        hidden: true
      },
      {
        path: 'deviceUpdate',
        name: 'deviceUpdate',
        component: () => import('@/views/deviceManagement/device/update'),
        meta: { title: '设备划拨', icon: 'dots', isHidden: false },
        hidden: true
      },
      {
        path: 'channel',
        name: 'channel',
        component: () => import('@/views/deviceManagement/gb28181/channel'),
        meta: { title: '通道列表', icon: 'dots' }
      },
      {
        path: 'personnel',
        name: 'personnel',
        component: () => import('@/views/deviceManagement/personnel/index'),
        meta: { title: '风险应急小组', icon: '&#xe721;' }
      },
      {
        path: 'iot',
        name: 'iot',
        component: () => import('@/views/deviceManagement/iot/index'),
        meta: { title: 'IOT监控', icon: '&#xe71a;' }
      },
      {
        path: 'manual',
        name: 'manual',
        component: () => import('@/views/deviceManagement/manual/index'),
        meta: { title: '人工辅助', icon: '&#xe71a;' }
      },
      {
        path: 'serveManagement',
        component: () => import('@/views/deviceManagement/serveManagement/index'),
        redirect: 'serveManagement/list',
        meta: { title: '算法服务管理', icon: '&#xe71a;' },
        children: [
          {
            path: 'list',
            component: () => import('@/views/deviceManagement/serveManagement/list'),
            meta: { title: '算法服务管理', icon: '&#xe71a;' }
          },
          {
            path: 'detail',
            component: () => import('@/views/deviceManagement/serveManagement/detail'),
            meta: {
              title: '查看详情',
              icon: '&#xe71a;',
              isHidden: true,
              fatherPath: '/deviceManagement/list'
            },
            props: route => route.query
          }
        ]
      },
      {
        path: 'blacklist',
        component: () => import('@/views/deviceManagement/blacklist/index'),
        meta: { title: '设备黑名单', icon: '&#xe71a;' }
      }
    ]
  },
  {
    path: '/jobManagement',
    component: Layout,
    redirect: 'jobManagement/job',
    name: 'jobManagement',
    meta: {
      title: '作业管理',
      icon: '&#xe720;'
    },
    children: [
      {
        path: 'job',
        name: 'job',
        component: () => import('@/views/jobManagement/job/index'),
        meta: { title: '作业列表', icon: 'dots' }
      },
      {
        path: 'contractors',
        name: 'contractors',
        component: () => import('@/views/jobManagement/contractor/index'),
        meta: { title: '承包商列表', icon: 'dots' }
      },
      {
        path: 'jobDetails',
        name: 'jobDetails',
        component: () => import('@/views/jobManagement/job/details'),
        meta: {
          title: '作业详情',
          icon: 'dots',
          isHidden: true,
          fatherPath: '/jobManagement/job'
        },
        hidden: true
      },
      {
        path: 'contractorDetails',
        name: 'contractorDetails',
        component: () => import('@/views/jobManagement/contractor/details'),
        meta: {
          title: '经销商详情',
          icon: 'dots',
          isHidden: true,
          fatherPath: '/jobManagement/contractors'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/contractorManagement',
    component: Layout,
    redirect: 'contractorManagement/serverRecordDetail',
    name: 'componentManagement',
    children: [
      {
        path: 'contractorDirectory',
        name: 'contractorDirectory',
        component: () => import('@/views/componentManagement/contractorDirectory/index'),
        meta: { title: '承包商名录', icon: 'dots' }
      },
      {
        path: 'serverRecord',
        name: 'serverRecord',
        component: () => import('@/views/componentManagement/serverRecord/index'),
        meta: { title: '服务记录', icon: 'dots' }
      },
      {
        path: 'serverRecordDetail',
        name: 'serverRecordDetail',
        component: () => import('@/views/componentManagement/serverRecord/details'),
        meta: {
          title: '服务记录详情',
          icon: 'dots',
          isHidden: true,
          fatherPath: '/contractorManagement/serverRecord'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/vod',
    component: Layout,
    redirect: 'vod/live',
    name: 'vod',
    children: [
      {
        path: 'live',
        name: 'live',
        component: () => import('@/views/videoCenter/live/index'),
        meta: { title: '直播列表', icon: 'dots' }
      },
      {
        path: 'historicalVideo',
        name: 'historicalVideo',
        component: () => import('@/views/videoCenter/historicalVideo/index'),
        meta: { title: '历史视频', icon: 'dots' }
      },
      {
        path: 'lineation',
        name: 'lineation',
        component: () => import('@/views/videoCenter/lineation/index'),
        meta: { title: '画线', icon: 'dots' }
      },
      {
        path: 'screen',
        name: 'screen',
        component: () => import('@/views/videoCenter/screen/index'),
        meta: { title: '分屏', icon: 'dots' }
      }
    ]
  },
  {
    path: '/algorithm',
    component: Layout,
    redirect: '/algorithm/management',
    name: 'algorithm',
    meta: { title: '算法管理', icon: '&#xe71e;' },
    children: [
      {
        path: 'face',
        name: 'face',
        component: () => import('@/views/algorithm/face/index'),
        meta: { title: '人脸识别', icon: 'dots' }
      },
      {
        path: 'addRisk',
        name: 'addRisk',
        component: () => import('@/views/algorithm/addRisk/index'),
        meta: { title: '新增警情级别', icon: 'dots' }
      },
      {
        path: 'managementDetails',
        name: 'managementDetails',
        component: () => import('@/views/algorithm/management/details'),
        meta: {
          title: '事件详情',
          icon: 'product-add',
          isHidden: true,
          fatherPath: '/algorithm/management'
        },
        hidden: true
      },
      {
        path: 'management',
        name: 'management',
        component: () => import('@/views/algorithm/management/index'),
        meta: { title: '算法', icon: 'dots' }
      },
      {
        path: 'addAIModel',
        name: 'addAIModel',
        component: () => import('@/views/algorithm/addAIModel/index'),
        meta: { title: '新增AI模型', icon: 'dots' }
      },
      {
        path: 'aiModelList',
        name: 'aiModelList',
        component: () => import('@/views/algorithm/aiModelList/index'),
        meta: { title: 'AI模型展示', icon: 'dots' }
      },
      {
        path: 'modelDetail',
        name: 'modelDetail',
        component: () => import('@/views/algorithm/aiModelList/Detail.vue'),
        meta: {
          title: 'AI模型详情',
          icon: 'product-add',
          isHidden: true,
          fatherPath: '/algorithm/aiModelList'
        },
        hidden: true
      },
      {
        path: 'aiModeling',
        name: 'aiModeling',
        component: () => import('@/views/algorithm/aiModeling/index'),
        meta: { title: '免代码AI建模', icon: 'dots' }
      },
      {
        path: 'aiModelingDetail',
        name: 'aiModelingDetail',
        component: () => import('@/views/algorithm/aiModeling/Detail.vue'),
        meta: {
          title: '免代码AI建模详情',
          icon: 'product-add',
          isHidden: true,
          fatherPath: '/algorithm/aiModeling'
        },
        hidden: true
      },
      {
        path: 'helmet',
        name: 'helmet',
        component: () => import('@/views/algorithm/helmet/index'),
        meta: { title: '安全帽识别', icon: 'dots' }
      },
      {
        path: 'upAndDown',
        name: 'upAndDown',
        component: () => import('@/views/algorithm/upAndDown/index'),
        meta: { title: '上抛下掷识别', icon: 'dots' }
      },
      {
        path: 'upAndDownTheStairs',
        name: 'upAndDownTheStairs',
        component: () => import('@/views/algorithm/upAndDownTheStairs/index'),
        meta: { title: '上下楼梯识别', icon: 'dots' }
      },
      {
        path: 'smoke',
        name: 'smoke',
        component: () => import('@/views/algorithm/smoke/index'),
        meta: { title: '吸烟识别', icon: 'dots' }
      },
      {
        path: 'fence',
        name: 'fence',
        component: () => import('@/views/algorithm/e-fence/index'),
        meta: { title: '电子围栏', icon: 'dots' }
      },
      {
        path: 'cellphone',
        name: 'cellphone',
        component: () => import('@/views/algorithm/cellphone/index'),
        meta: { title: '打电话/看手机识别', icon: 'dots' }
      },
      {
        path: 'workerPostion',
        name: 'workerPostion',
        component: () => import('@/views/algorithm/position/index'),
        meta: { title: '作业人员站位', icon: 'dots' }
      },
      {
        path: 'tooling',
        name: 'tooling',
        component: () => import('@/views/algorithm/wear/index'),
        meta: { title: '工装识别', icon: 'dots' }
      },
      // {
      //   path: 'cigarette',
      //   name: 'cigarette',
      //   component: () => import('@/views/algorithm/goggles/index'),
      //   meta: {title: '吸烟识别', icon: 'order-return'}
      // },
      // {
      //   path: 'fire',
      //   name: 'fire',
      //   component: () => import('@/views/algorithm/goggles/index'),
      //   meta: {title: '明火识别', icon: 'order-return'}
      // },
      // {
      //   path: 'smoke',
      //   name: 'smoke',
      //   component: () => import('@/views/algorithm/goggles/index'),
      //   meta: {title: '烟雾识别', icon: 'order-return'}
      // },
      {
        path: 'falldown',
        name: 'falldown',
        component: () => import('@/views/algorithm/falldown/index'),
        meta: { title: '倒地识别', icon: 'dots' }
      }
    ]
  },
  {
    path: '/illegal',
    component: Layout,
    redirect: '/illegal/list',
    name: 'illegalmanagement',
    meta: { title: '事件管理', icon: 'order' },
    children: [
      {
        path: 'delError',
        name: 'delError',
        component: () => import('@/views/illegalManagement/delError/index'),
        meta: { title: 'del', icon: '&#xe725;' }
      },
      {
        path: 'list',
        name: 'list',
        component: () => import('@/views/illegalManagement/list/index'),
        meta: { title: '事件列表', icon: '&#xe725;' }
      },
      {
        path: 'hRingSOSList',
        name: 'hRingSOSList',
        component: () => import('@/views/illegalManagement/list/hRingSOSList'),
        meta: { title: 'SOS报警记录', icon: '&#xe725;' }
      },
      {
        path: 'uploadPictures',
        name: 'uploadPictures',
        component: () => import('@/views/illegalManagement/list/uploadPictures'),
        meta: { title: '智能头环图传记录', icon: '&#xe725;' }
      },
      {
        path: 'eventDetails',
        name: 'eventDetails',
        component: () => import('@/views/illegalManagement/list/details'),
        meta: {
          title: '事件详情',
          icon: 'product-add',
          isHidden: true,
          fatherPath: '/illegal/list'
        },
        hidden: true,
        beforeEnter: (to, from, next) => {
          if (from.path !== '/') {
            localStorage.setItem('sourceRouter', JSON.stringify({ path: from.path, fullPath: from.fullPath }))
          }
          next()
        }
      },
      {
        path: 'peopleFlow',
        name: 'peopleFlow',
        component: () => import('@/views/illegalManagement/peopleFlow/index'),
        meta: { title: '人流量统计列表', icon: '&#xe725;' }
      },
      {
        path: 'peopleFlowDetail',
        name: 'peopleFlowDetail',
        component: () => import('@/views/illegalManagement/peopleFlow/detail'),
        meta: { title: '事件详情', icon: '&#xe725;' }
      },
      {
        path: 'safetyman',
        name: 'safetyman',
        component: () => import('@/views/illegalManagement/safetyman/index'),
        meta: { title: '应急人员', icon: 'dots' }
      },
      {
        path: 'pushmessage',
        name: 'pushmessage',
        component: () => import('@/views/illegalManagement/pushmessage/index'),
        meta: { title: '平台推送', icon: 'dots' }
      }
      // {
      //   path: 'video',
      //   name: 'video',
      //   component: () => import('@/views/illegalManagement/video/index'),
      //   meta: {
      //     title: '视频中心',
      //     icon: 'order-setting'
      //   }
      // },
    ]
  },
  {
    path: '/sysconfig',
    component: Layout,
    redirect: '/sysconfig/users',
    name: 'sysconfig',
    meta: { title: '系统设置', icon: '&#xe727;' },
    children: [
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/sysconfig/users/index'),
        meta: { title: '账户管理', icon: 'dots' }
      },
      {
        path: 'accountMonitoring',
        component: () => import('@/views/sysconfig/accountMonitoring/view'),
        meta: { title: '账号离线监控', icon: 'dots' },
        children: [
          {
            path: '',
            name: 'accountMonitoring',
            component: () => import('@/views/sysconfig/accountMonitoring/index'),
            meta: { title: '账号离线监控', icon: 'dots' }
          },
          {
            path: 'history/:username',
            name: 'accountHistory',
            component: () => import('@/views/sysconfig/accountMonitoring/history'),
            meta: { title: '在离线历史统计', icon: 'dots', isHidden: true, fatherPath: '/sysconfig/accountMonitoring' },
            props: true
          }
        ]
      },
      {
        path: 'setHRing',
        name: 'setHRing',
        component: () => import('@/views/sysconfig/setHRing/index'),
        meta: { title: '智能头环设置', icon: 'dots' }
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: () => import('@/views/sysconfig/permissions/index'),
        meta: { title: '权限管理', icon: 'dots' }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/sysconfig/role/index'),
        meta: { title: '角色管理', icon: 'dots' }
      },
      {
        path: 'organization',
        name: 'organization',
        component: () => import('@/views/sysconfig/organization/index'),
        meta: { title: '组织结构管理', icon: 'dots' }
      },
      {
        path: 'log',
        name: 'log',
        component: () => import('@/views/sysconfig/log/Layout'),
        redirect: '/sysconfig/log/list',
        meta: { title: '日志管理', icon: 'dots' },
        children: [
          {
            path: 'list',
            name: 'log_index',
            component: () => import('@/views/sysconfig/log/index'),
            meta: { title: '日志列表', icon: '&#xe725;' }
          },
          {
            path: 'detail',
            name: 'log_detail',
            component: () => import('@/views/sysconfig/log/detail'),
            meta: { title: '事件详情', icon: 'product-add', isHidden: true },
            hidden: true
          }
        ]
      },
      {
        path: 'videos',
        component: () => import('@/views/sysconfig/videos/Layout'),
        redirect: '/sysconfig/videos/index',
        name: 'videos',
        meta: { title: '视频源管理', icon: 'dots' }
      },
      {
        path: 'loginLog',
        name: 'loginLog',
        component: () => import('@/views/sysconfig/loginLog'),
        meta: { title: '登录日志', icon: 'dots' }
      }
    ]
  },
  {
    path: '/error',
    component: Layout,
    redirect: '/error/no-permission',
    name: 'error',
    children: [
      {
        path: 'no-permission',
        name: 'no-permission',
        component: () => import('@/views/error/noPermission/index'),
        meta: { title: '暂无权限', icon: 'dots', isHidden: true }
      }
    ],
    hidden: true
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401'),
    hidden: true
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true
  },
  {
    path: '/hRing',
    name: 'hRing',
    component: () => import('@/views/deviceManagement/iot/hRing.vue'),
    hidden: false
  },
  {
    path: '/msg',
    component: Layout,
    redirect: '/msg/list',
    name: 'messageCenter',
    meta: { title: '消息中心', icon: '&#xe760;' },
    children: [
      {
        path: 'list',
        name: 'messageList',
        component: () => import('@/views/messageCenter/messageView/index'),
        children: [
          {
            path: '',
            component: () => import('@/views/messageCenter/messageList/index'),
            meta: { title: '消息列表', icon: '&#xe721;' },
            props: route => ({ searchCache: route.query?.searchCache, pageCache: route.query?.pageCache })
          },
          {
            path: 'create',
            component: () => import('@/views/messageCenter/createMessage/index'),
            meta: { title: '新建消息', icon: '&#xe721;', isHidden: true, fatherPath: '/msg/list' }
          },
          {
            path: 'detail/:msgId',
            component: () => import('@/views/messageCenter/detailMessage/index'),
            meta: { title: '消息详情', icon: '&#xe721;', isHidden: true, fatherPath: '/msg/list' },
            props: route => ({
              msgId: route.params?.msgId,
              searchCache: route.query?.searchCache,
              pageCache: route.query?.pageCache
            })
          }
        ]
      },
      {
        path: 'config',
        name: 'messageConfig',
        component: () => import('@/views/messageCenter/messageConfig/index'),
        meta: { title: '消息设置', icon: '&#xe721;' }
      }
    ]
  },
  {
    path: '/electronicFence',
    name: 'mapManage',
    component: Layout,
    redirect: '/electronicFence/mapList',
    meta: { title: '电子围栏', icon: '&#xe721;' },
    children: [
      {
        path: 'mapList',
        name: 'mapList',
        component: () => import('@/views/electronicFence/mapManage/index.vue'),
        meta: { title: '地图管理', icon: '&#xe721;' }
      },
      {
        path: 'mapDetail',
        name: 'mapDetail',
        component: () => import('@/views/electronicFence/mapManage/detail.vue'),
        meta: { title: '地图详情', icon: '&#xe721;' }
      },
      {
        path: 'electronicFenceManage',
        name: 'electronicFenceManage',
        component: () => import('@/views/electronicFence/electronicFenceMange/index.vue'),
        meta: { title: '电子围栏管理', icon: '&#xe721;' }
      },
      {
        path: 'baseStationManage',
        name: 'baseStationManage',
        component: () => import('@/views/electronicFence/baseStationManage'),
        meta: { title: '基站管理', icon: '&#xe721;' }
      },
      {
        path: 'userTagMange',
        name: 'userTagMange',
        component: () => import('@/views/electronicFence/userTag/index.vue'),
        meta: { title: '用户标签管理', icon: '&#xe721;' }
      },
      {
        path: 'userTrack',
        name: 'userTrack',
        component: () => import('@/views/electronicFence/userTag/track.vue'),
        meta: { title: '轨迹回放', icon: '&#xe721;' }
      },
      {
        path: 'fenceEvent',
        name: 'fenceEvent',
        component: () => import('@/views/electronicFence/fenceEventManage/index.vue'),
        meta: { title: '围栏事件管理', icon: '&#xe721;' }
      },
      {
        path: 'eventTrack',
        name: 'eventTrack',
        component: () => import('@/views/electronicFence/fenceEventManage/eventTrack.vue'),
        meta: { title: '轨迹回放', icon: '&#xe721;' }
      }
    ]
  }
]

export default new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
