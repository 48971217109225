import router from './router'
import store from './store/index'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import { getToken } from '@/utils/auth' // 验权
import { EXT_PAGE } from '@/utils/constant'

const whiteList = ['/login'] // 不重定向白名单
let url = window.location.href
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (!to.meta.isHidden) {
    if (to.path === EXT_PAGE) {
      setTimeout(() => {
        let path = localStorage.getItem(EXT_PAGE)
        store.dispatch('SetActivePath', path)
      })
    } else {
      store.dispatch('SetActivePath', to.path)
    }
  } else {
    // fullPath为带参数的全路由
    // 设备详情为例
    // path:/deviceManagement/deviceDetails
    // fullPath:/deviceManagement/deviceDetails?id=11
    var onRouter = to.meta.fatherPath
    store.dispatch('SetActivePath', onRouter)
  }
  // 用于判断当前路由是否为可视路由
  // if (store.getters.originMenuList.length > 0) {
  //   const originMenuList = store.getters.originMenuList
  //   originMenuList.forEach(item => {
  //     if (item.route === to.path.split('/')[2]) {
  //       if (item.fid === 8) {
  //         next({
  //           path: "/403"
  //         });
  //       }
  //     }
  //   })
  // }
  /**
   * 当url中携带 username & password 时，直接登录，然后跳转到路由对应的页面如：
   * http://192.168.31.7:8080/#/vod/live?username=abcde&password=3b1b0d2ed4dedc0e17efea0375f5fe41b3c23781dd1316be22a730dfabc07993
   * 该地址会直接登录，登录成功之后会跳转到路由为/vod/live的页面
   */
  const { username, password } = to.query
  if (username && password) {
    if (getToken()) {
      next(to.path)
    } else {
      store
        .dispatch('Login', { username, password, isSha: true })
        .then(async () => {
          await store.dispatch('GetInfo')
          next(to.path)
        })
        .catch(() => {
          next('/login')
        })
    }
    NProgress.done()
  } else if (getToken()) {
    if (to.path === '/login') {
      next({
        path: '/'
      })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      next()
      if (store.getters.menuList.length === 0) {
        store
          .dispatch('GetInfo')
          .then(() => {
            if (from.path === '/login') {
              if (store.getters.menuList[0].children) {
                next({
                  path: store.getters.menuList[0].children[0].path
                })
              } else {
                next({
                  path: store.getters.menuList[0].path
                })
              }
            }
            // next();
          })
          .catch(() => {
            // store.dispatch("FedLogOut").then(() => {
            //   next({
            //     path: "/login"
            //   });
            // });
          })
      } else {
        next()
        // 拿到的数据是当前路由下的功能api，目的是准备做按钮的显隐
        // const originMenuList = store.getters.originMenuList;
        // const list = store.getters.originMenuList;
        // let permissionList = [];
        // originMenuList.forEach(item => {
        //   if (item.component === to.path) {
        //     list.forEach(permission => {
        //       if (item.id === permission.pid) {
        //         permissionList.push(permission);
        //       }
        //     });
        //   }
        // });
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (url.includes('?code=') && url.includes('&state=')) {
        const codeReg = /\?code=(.*?)&state/g
        const stateReg = /state=(\d+)/g
        let wxCode = codeReg.exec(url)[1].replace('/', '')
        let wxState = stateReg.exec(url)[1]
        window.location.href = '/#/bind?wxCode=' + wxCode + '&wxState=' + wxState
      }
      if (to.path === '/bind') {
        next()
      } else {
        next('/login')
      }
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
